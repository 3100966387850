import axios from "axios"

const apiUri = process.env.REACT_APP_API;

export const uploadImage = async (file: File, folder: string, filename: string) : Promise<string> => {
    const data = new FormData();
    data.append("photos", file);
    const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
    const response = await axios.post(apiUri + "/media/single", data, {headers: {folder, filename, extension}});
    return response.data.url;
}