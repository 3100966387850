import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createVolume, getVolume, getVolumes } from "../backend/volumes";
import Loading from "../components/animations/Loading";
import OverlayWindow from "../components/overlay/OverlayWindow";
import { Volume } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import { useOverlay } from "../context/OverlayProvider";
import "./styles/Editor.css"

function Volumes() {
    const { t } = useTranslation();
    const [volumes, setVolumes] = useState<Volume[]>([]);
    const [volumeForm, setVolumeForm] = useState<Volume>();
    const [newVolumeName, setNewVolumeName] = useState("");
    const [selectedVolumeIndex, setSelectedVolumeIndex] = useState(0);
    const [done, setDone] = useState(false);
    const [, setOverlay] = useOverlay();
    const {user, createToken} = useAuth();

    useEffect(() => {
        if (!done) download();
    }, [done])

    useEffect(() => {
        if (done) updateVolume();
    }, [selectedVolumeIndex])

    async function download() {
        const downloadedVolumes = await getVolumes();
        const downloadedVolume = await getVolume(downloadedVolumes[selectedVolumeIndex]);
        await createToken();
        setVolumes(downloadedVolumes);
        setVolumeForm(downloadedVolume);
        setDone(true);
    }

    async function updateVolume() {
        const downloadedVolume = await getVolume(volumes[selectedVolumeIndex]);
        setVolumeForm(downloadedVolume);
    }

    async function handleSubmit() {
        try {
            
        } catch (error) {
            const errorString = error as string;
            setOverlay(overlay => { return { ...overlay, display: "flex", content: <OverlayWindow>{errorString}</OverlayWindow>}})
            console.log(error);
        }
    }

    async function addVolume(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            if (newVolumeName === "") return;
            await createVolume(newVolumeName);
            setDone(false);
            setOverlay(overlay => { return { ...overlay, display: "flex", content: <OverlayWindow>{"Sucess"}</OverlayWindow>}});
        } catch (error) {
            const errorString = error as string;
            setOverlay(overlay => { return { ...overlay, display: "flex", content: <OverlayWindow>{errorString}</OverlayWindow>}});
            console.log(error);
        }
    }

    if (!done) return <Loading/>
    
    return (
        <div className="limited-area">
            <div className="centered-area flex-column" style={{"flex": "1 2", "gap": "30px"}}>
                <div className="centered-area expandw flex-column">
                    {t("Select volume") + ":"}
                    <select value={selectedVolumeIndex} className="flex-fill case-editor-select" onChange={(e) => setSelectedVolumeIndex(e.target.selectedIndex)}>
                        {volumes.map((volume, index) => (
                            <option key={volume.id} value={index}>{volume.id.split("/", 2)[1]}</option>
                        ))}
                    </select>
                </div>
                <form onSubmit={addVolume} className="centered-area expandw flex-column" style={{"gap": "20px"}}>
                    <div className="flex gap15" style={{"alignItems": "center", "justifyContent": "flex-end"}}>
                        <label>New volume: </label>
                        <input className="flex-fill case-editor-select" style={{"maxWidth": "400px"}} value={newVolumeName} onChange={e => setNewVolumeName(e.target.value)}/>
                        <button className="button-purple" style={{"width": "200px"}}>{t("Add")}</button>
                    </div>
                </form>
                <form className="centered-area expandw flex-column" style={{"gap": "20px"}}>
                    <h2 style={{"margin": "0"}}>{t("Selected volume properties")}</h2>
                    <div>
                        <label>Volumenes:</label><input type={"file"} accept="image/png" multiple/>
                    </div>
                    <div>
                        <div>Imagenes actuales:</div>
                        {volumeForm?.images.map((image, index) => (
                            <div key={"volume-image-" + index}>
                                <a href={image}>{image}</a>
                            </div>
                        ))}
                    </div>
                    <button className="button-purple">{t("Submit")}</button>
                </form>
            </div>
        </div>
    );
}

export default Volumes;