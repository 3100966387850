
import { Timestamp } from "firebase/firestore";
import { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import A from "../components/navigation/A";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthProvider";
import "./styles/Pricing.css"

function Pricing() {
    const { t } = useTranslation();
    const [standardButton, setStandardButton] = useState<CSSProperties>();
    
    return (
        <div className="limited-area pricing-area">
            <div className="fuve-products-container centered-area">
                <div className="fuve-product">
                    <div className="fuve-product-title">{t("Premium")}</div>
                    <div className="fuve-product-price">{t("$1000 USD")}</div>
                    <div className="fuve-product-time">{t("1 Year")}</div>
                    <div className="fuve-product-shortdesc">{t("Subscripción-completa")}</div>
                    <div className="fuve-product-features">
                        {t("Acceso-completa")}<br />
                    </div>
                    <A href="/payment/premium" className="button-purple fuve-product-button"><div>{t("Adquirir")}</div></A>
                </div>
                <div className="fuve-product">
                    <div className="fuve-product-title">{t("Fetal Pathology")}</div>
                    <div className="fuve-product-price">{t("$250 USD")}</div>
                    <div className="fuve-product-time">{t("3 Months")}</div>
                    <div className="fuve-product-shortdesc">{t("Basic user subscription")}</div>
                    <div className="fuve-product-features">
                        {t("Access to all fetal pathology course cases")}<br />
                    </div>
                    <A href="/payment/standard" className="button-purple fuve-product-button">
                        <div>
                            {t("Adquirir")}
                        </div>
                    </A>
                </div>
                <div className="fuve-product">
                    <div className="fuve-product-title">{t("Fetal Morphology")}</div>
                    <div className="fuve-product-price">{t("$150 USD")}</div>
                    <div className="fuve-product-time">{t("3 Months")}</div>
                    <div className="fuve-product-shortdesc">{t("Basic user subscription")}</div>
                    <div className="fuve-product-features">
                        {t("Access to all fetal morphology course cases")}<br />
                    </div>
                    <A href="/payment/morphology" className="button-purple fuve-product-button">
                        <div>
                            {t("Adquirir")}
                        </div>
                    </A>
                </div>
                <div className="fuve-product">
                    <div className="fuve-product-title">{t("Educacional")}</div>
                    <div className="fuve-product-price">Let's Talk</div>
                    <div className="fuve-product-time"><br/></div>
                    <div className="fuve-product-shortdesc">{t("Subscripción-educativa")}</div>
                    <div className="fuve-product-features">
                        {t("Acceso-fuve")}<br />
                    </div>
                    <A href="mailto:educacion@medicinafetalmexico.com" className="button-purple fuve-product-button"><div>{t("Contáctanos")}</div></A>
                </div>
            </div>
        </div>
    );
}

export default Pricing;