import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../backend/files";
import { db } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import MultiTextInput from "../components/input/MultiTextInput";
import { Category, Section } from "../components/types/CustomTypes";
import { useLanguage } from "../context/LanguageProvider";
import "./styles/Editor.css"

function CategoryEditor() {
    const [categories, setCategories] = useState<Category[]>([]);
    const [sections, setSections] = useState<Section[]>([]);
    const [categoryForm, setCategoryForm] = useState<Category>();
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    const [done, setDone] = useState(false);
    const { t } = useTranslation();
    const [fileData, setFileData] = useState<File>();
    const [language] = useLanguage();

    const fileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFileData(e.target.files![0]);
    };

    const downloadMainData = async () => {
        const categoryQuery = query(collection(db, "categories"));
        const categoriesSnapshot = await getDocs(categoryQuery);
        const downloadedCategories = categoriesSnapshot.docs.map(doc => { return doc.data() as Category });
        const sectionQuery = query(collection(db, "sections"));
        const sectionsSnapshot = await getDocs(sectionQuery);
        const downloadedSections = sectionsSnapshot.docs.map(doc => { return doc.data() as Section });
        setCategories(downloadedCategories);
        setSections(downloadedSections);
        setDone(true);
    }

    async function addCategory() {
        try {
            let newCategory: Category = {
                id: "",
                title: {
                    es: "Nueva categoria",
                    en: "New category"
                }
            }
            const docRef = await addDoc(collection(db, "categories"), newCategory);
            await updateDoc(doc(collection(db, "categories"), docRef.id), { id: docRef.id });
            setSelectedCategoryIndex(0);
            setDone(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteCategory() {
        try {
            if (window.confirm('¿Esta seguro que desea eliminar la categoria <' + categoryForm?.title[language] + '>?'))
            {
                const backupDoc = await getDoc(doc(db, "categories", categoryForm!.id));
                await setDoc(doc(db, "categories_recycle_bin", categoryForm!.id), backupDoc.data());
                await deleteDoc(doc(db, "categories", categoryForm!.id));
                setSelectedCategoryIndex(0);
                setDone(false);
            }
        } catch (error) {
            console.log(error);
        }
        
    }

    useEffect(() => {
        if (!done) downloadMainData();
    }, [done])

    useEffect(() => {
        if (categories.length > 0) {
            let potentialCategoryForm = categories[selectedCategoryIndex]
            if (!potentialCategoryForm.section) potentialCategoryForm.section = "null";
            setCategoryForm(potentialCategoryForm);
        }
    }, [categories, selectedCategoryIndex])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("file data ====>", fileData);
        try {
            if (fileData) {
                const iconUri = await uploadImage(fileData, "Icons/Categories", categoryForm!.id);
                setCategoryForm(category => {return {...category!, icon: iconUri}});
                await updateDoc(doc(db, "categories", categoryForm!.id), {...categoryForm, icon: iconUri});
            }
            else await updateDoc(doc(db, "categories", categoryForm!.id), categoryForm);
            setSelectedCategoryIndex(0);
            setDone(false);
        } catch (error) {
            console.log(error);
        }
    }

    if (!done) return <Loading/>
    console.log(categoryForm?.section);
    return (
        <>
        {categoryForm && (
            <div className="limited-area">
                <div className="centered-area" style={{"flex": "1 2"}}>
                    <form onSubmit={handleSubmit} className="centered-area expandw flex-column" style={{"gap": "20px"}}>
                        {t("Case") + ":"}
                        <select className="flex-fill case-editor-select" onChange={(e) => setSelectedCategoryIndex(e.target.selectedIndex)}>
                            {categories.map((category, index) => (
                                <option key={category.id} value={index}>{category.title.es}</option>
                            ))}
                        </select>
                        <div className="flex gap15">
                            <div onClick={addCategory} className="button-purple limited-area" style={{ "maxWidth": "200px" }}>Add category</div>
                            <div onClick={deleteCategory} className="button-red limited-area" style={{ "maxWidth": "200px" }}>Delete category</div>
                        </div>
                        <div>{t("Section")}:</div>
                        <select value={categoryForm.section} onChange={(e) => setCategoryForm(form => { return {...form!, section: e.target.value}})} className="flex-fill case-editor-select" style={{ "maxHeight": "30px" }}>
                            <option value={"null"}>{t("No section")}</option>
                            {sections.map(section => (
                                <option key={section.id} value={section.id}>{section.title[language]}</option>
                            ))}
                        </select>
                        <div className="case-editor-multi">
                            <MultiTextInput value={categoryForm.title} field="title" setForm={setCategoryForm}/>
                        </div>
                        <div className="case-editor-multi">
                            <input type={"file"} accept="image/jpeg,image/png" onChange={fileChangeHandler}/>
                            <img src={categoryForm.icon} style={{"width": "30px", "height": "30px"}}/>
                        </div>
                        <button className="button-purple">Submit</button>
                    </form>
                </div>
            </div>
        )}
        </>
    );
}

export default CategoryEditor;