import './styles/Contact.css';
import { useState } from "react";

function Contact() {
    const [typeHelp, setTypeHelp] = useState("Información sobre FUVE");

    return (
        <div className="intro">
            <div className="subscribe">
                <div className="subscribe-content">
                    <div className='intro-subscribe'>
                        <h2 style={{"fontSize": "35px"}}>¡Bienvenido a FUVE!</h2>
                        <p>
                            Es un placer atenderte, por favor llena el siguiente
                            formulario para brindarte el apoyo que necesitas.
                        </p>
                        <img className="subscribe-img" src="/img-contact.png" />
                    </div>
                    <div>
                        <form
                            className="subscribe-form"
                            action="https://formsubmit.co/wreyna@medicinafetalmexico.com"
                            method="POST"
                        >
                            <input type="hidden" name="_template" value="table"></input>
                            <input
                                type="hidden"
                                name="_subject"
                                value="Nuevo mensaje desde fuve.app/contact"
                            ></input>
                            <input
                                type="text"
                                placeholder="Escribe aquí tu nombre completo"
                                name="Nombre"
                                required
                            />
                            <input
                                type="email"
                                placeholder="Escribe aquí tu correo electrónico"
                                name="Email"
                                required
                            />
                            <input
                                type="text"
                                placeholder="¿Cómo podemos apoyarte?"
                                name="¿Cómo podemos apoyarte?"
                                required
                            />
                            <label htmlFor="help" className="lbl-help">
                                Tipo de ayuda
                                <select
                                    name="Tipo de ayuda"
                                    onChange={(e) => setTypeHelp(e.target.value)}
                                >
                                    <option value="Información sobre FUVE">
                                        Información sobre FUVE
                                    </option>
                                    <option value="FUVE Premium">FUVE Premium</option>
                                    <option value="Pagos">Pagos</option>
                                    <option value="Relaciones públicas">
                                        Relaciones públicas
                                    </option>
                                    <option value="Soporte y atención a cliente">
                                        Soporte y atención a cliente
                                    </option>
                                    <option value="Ventas">Ventas</option>
                                    <option value="Otro">Otro </option>
                                </select>
                            </label>
                            {typeHelp === "Otro" && (
                                <input
                                    type="text"
                                    placeholder="¿Qué tipo de ayuda requieres?"
                                    name="Otro"
                                    required
                                />
                            )}
                            <button
                                className="button-subscribe"
                                style={{ width: "100%", maxWidth: "100%" }}
                            >
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;