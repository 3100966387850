import { updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../backend/firebase";
import { useAuth, UserData } from "../context/AuthProvider";
import "./styles/Profile.css"

type UserForm = {
    medicalCenter: string,
    medicalLocation: string,
    specialty: string,
    subspecialty: string
}

function Profile() {
    const { t } = useTranslation();
    const { user, userData } = useAuth();
    const [form, setForm] = useState({
        name: user?.displayName || ""
    });
    const [userForm, setUserForm] = useState<UserForm>({
        medicalCenter: userData?.medicalCenter || "",
        medicalLocation: userData?.medicalLocation || "",
        specialty: userData?.specialty || "",
        subspecialty: userData?.subspecialty || "",
    });

    const handlePersonal = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await updateDoc(doc(db, "users", user!.uid), userForm);
        await updateProfile(user!, { displayName: form.name });
    }

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setForm(form => { return {...form, [e.target.name]: e.target.value}});
    }

    const handleUserData = async (e: ChangeEvent<HTMLInputElement>) => {
        setUserForm(form => { return {...form, [e.target.name]: e.target.value}});
    }

    return (
        <div className="profile-area">
            <div className="profile-card">
                <div className="profile-container">
                    <div style={{ "fontWeight": "700", "fontSize": "30px" }}>{t("Profile")}</div>
                    <form onSubmit={handlePersonal} style={{ "display": "flex", "gap": "15px", "flexDirection": "column" }}>
                        <div style={{ "fontWeight": "300", "marginBottom": "5px" }}>{t("Personal information")}</div>
                        <div style={{ "display": "flex", "gap": "10px", "justifyContent": "center", "alignItems": "center" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Full name:")}</div>
                            <input
                                name="name"
                                value={form.name}
                                onChange={handleChange}
                                style={{ "border": "0", "backgroundColor": "#0e0517", "height": "30px", "borderRadius": "10px", "color": "white", "display": "flex", "flex": "1 2" }} />
                        </div>
                        <div style={{ "fontWeight": "300", "marginBottom": "5px" }}>{t("Medical center")}</div>
                        <div style={{ "display": "flex", "gap": "10px", "justifyContent": "center", "alignItems": "center" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Name center:")}</div>
                            <input
                                name="medicalCenter"
                                value={userForm.medicalCenter}
                                onChange={handleUserData}
                                style={{ "border": "0", "backgroundColor": "#0e0517", "height": "30px", "borderRadius": "10px", "color": "white", "display": "flex", "flex": "1 2" }} />
                        </div>
                        <div style={{ "display": "flex", "gap": "10px", "justifyContent": "center", "alignItems": "center" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Location center:")}</div>
                            <input
                                name="medicalLocation"
                                value={userForm.medicalLocation}
                                onChange={handleUserData}                                
                                style={{ "border": "0", "backgroundColor": "#0e0517", "height": "30px", "borderRadius": "10px", "color": "white", "display": "flex", "flex": "1 2" }} />
                        </div>
                        <div style={{ "fontWeight": "300", "marginBottom": "5px" }}>{t("Do you have any specialty?")}</div>
                        <div style={{ "display": "flex", "gap": "10px", "justifyContent": "center", "alignItems": "center" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Medical specialization:")}</div>
                            <input
                                name="specialty"
                                value={userForm.specialty}
                                onChange={handleUserData}
                                style={{ "border": "0", "backgroundColor": "#0e0517", "height": "30px", "borderRadius": "10px", "color": "white", "display": "flex", "flex": "1 2" }} />
                        </div>
                        <div style={{ "display": "flex", "gap": "10px", "justifyContent": "center", "alignItems": "center" }}>
                            <div style={{ "fontWeight": "700" }}>{t("Medical subspecialty:")}</div>
                            <input
                                name="subspecialty"
                                value={userForm.subspecialty}
                                onChange={handleUserData}
                                style={{ "border": "0", "backgroundColor": "#0e0517", "height": "30px", "borderRadius": "10px", "color": "white", "display": "flex", "flex": "1 2" }} />
                        </div>
                        
                        {/*<div style={{ "display": "flex", "gap": "10px", "justifyContent": "center", "alignItems": "center" }}>
                            <div style={{ "fontWeight": "700" }}>Apellido:</div>
                            <input style={{ "border": "0", "backgroundColor": "#0e0517", "height": "30px",  "borderRadius": "10px", "color": "white", "display": "flex", "flex": "1 2"  }} />
                        </div>*/}
                        <button className="button-purple">{t("Submit")}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Profile;