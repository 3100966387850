import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { db } from '../backend/firebase';
import { getPayPalPlans, newPayPalSubscription } from '../backend/paypal';
import Loading from '../components/animations/Loading';
import OverlayWindow from '../components/overlay/OverlayWindow';
import { Plan, Subscription } from '../components/types/CustomTypes';
import { useAuth } from '../context/AuthProvider';
import { useOverlay } from '../context/OverlayProvider';
import { useTranslation } from "react-i18next";
import './styles/Payment.css'

function Payment() {
    const { productType } = useParams();
    const [plans, setPlans] = useState<Plan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<Plan>();
    const [done, setDone] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [, setOverlay] = useOverlay();
    const { user, subscriptions, setSubscriptions } = useAuth();
    const { t } = useTranslation();

    const downloadPlans = async () => {
        const downloadedPlans = await getPayPalPlans("fuve-" + productType);
        setPlans(downloadedPlans || []);
        if (downloadedPlans.length > 0) setSelectedPlan(downloadedPlans[0]);
        setDone(true);
    }

    useEffect(() => {
        console.log(user?.email);
        if (!done) downloadPlans();
    }, []);

    if (subscriptions?.find(subscription => 
        Timestamp.now() <= subscription.next_billing_time && (
            subscription.product_id === "fuve-" + productType ||
            subscription.product_id === "fuve-premium"
        ))) return <Navigate to="/courses" replace={true}/>

    return (
        <>
            {done ?
                <div className="limited-area checkout-container">
                    <div className="checkout-area centered-area">
                        <div>Checkout</div>
                        <div className='checkout-payment-area'>
                            <div className='checkout-left'>
                                <div className="checkout-product-area">
                                    {plans.map(plan => (
                                        <div key={plan.id} className="checkout-product" onClick={() => setSelectedPlan(plan)}>
                                            <div style={{"flex": "1 2"}}>{plan.name + " "}</div>
                                            <div className="checkout-product-price">
                                                ${plan.billing_cycles[0].pricing_scheme.fixed_price.value + " "}
                                                {plan.billing_cycles[0].pricing_scheme.fixed_price.currency_code}
                                                {/*plan.billing_cycles[0].frequency.interval_count && plan.billing_cycles[0].frequency.interval_count > 1 && (<>{" " + plan.billing_cycles[0].frequency.interval_count}</>)}
                                                {" " + (plan.billing_cycles[0].frequency.interval_unit ? t(plan.billing_cycles[0].frequency.interval_unit) : "")*/}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='checkout-right'>
                                <h2 className="checkout-product-price">{t("your-order")}</h2>
                                <div className="checkout-product-price">
                                    ${selectedPlan?.billing_cycles[0].pricing_scheme.fixed_price.value + " "}
                                    {selectedPlan?.billing_cycles[0].pricing_scheme.fixed_price.currency_code}
                                    {/*selectedPlan?.billing_cycles[0].frequency.interval_count && selectedPlan?.billing_cycles[0].frequency.interval_count > 1 && (<>{" " + selectedPlan?.billing_cycles[0].frequency.interval_count}</>)}
                                    {" " + (selectedPlan?.billing_cycles[0].frequency.interval_unit ? t(selectedPlan?.billing_cycles[0].frequency.interval_unit) : "")*/}
                                </div>
                                <div style={{"fontWeight": "700"}}>{t("Coupon: ")}
                                    <input value={coupon} onChange={(e) => setCoupon(e.target.value)} className='checkout-coupon'/>
                                </div>
                                {selectedPlan && 
                                <PayPalButtons
                                    forceReRender={[selectedPlan]}
                                    style={{"color": "black"}}
                                    className="custom-pay"
                                    createSubscription={(data, actions) => {
                                        console.log("creando suscripcion para: ", selectedPlan.id);
                                        return actions.subscription.create({
                                            "plan_id": selectedPlan.id
                                        })
                                    }}
                                    onApprove={async (data, actions) => {
                                        const details = await actions.subscription?.get();
                                            console.log(details);
                                            console.log((details as any).id);
                                        const newSubscription: Subscription = {
                                            provider: "paypal",
                                            subscription_id: data.subscriptionID || "",
                                            status: (details as any).status || "",
                                            product_id: "fuve-" + productType,
                                            next_billing_time: (details as any).billing_info?.next_billing_time || "",
                                            plan_name: selectedPlan.name
                                        }
                                        if (user) await newPayPalSubscription(user.uid, newSubscription);
                                        setOverlay({display: "flex", content: (
                                            <OverlayWindow>
                                                <img src="/img/correct-icon.png" style={{ "width": "60px", "height": "60px" }} />
                                                <h1>{t("succes-operation")}</h1>
                                                <p>{t("correct-payment")}</p>
                                            </OverlayWindow>
                                        )});
                                        setTimeout(function(){
                                            setSubscriptions(subscriptions => [...subscriptions, newSubscription]);
                                       }, 1000);
                                    }}
                                />}
                                {/*selectedPlan && selectedPlan.id === "P-8JH592288L478613SMLWULFI" && coupon !== "F8GV3X" && (<div style={{"marginBottom": "20px"}}>{t("This plan requires a coupon")}</div>)*/}
                            </div>
                        </div>
                    </div>
                </div>
                : <Loading />}
        </>
    );
}

const nothing = async (options: string) => {return ""}

export default Payment;