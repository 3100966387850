import { FirebaseError } from "firebase/app";
import { updateProfile } from "firebase/auth";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import A from "../components/navigation/A";
import { CustomizedState } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import "./styles/Signup.css"

function Signup() {
    const [user, setUser] = useState({ email: "", password: "", verifyPassword: "", name: "", lastName: ""});
    const [error, setError] = useState("");
    const { t } = useTranslation();
    const { login, loginWithApple, loginWithFacebook, loginWithGoogle, signup, loading } = useAuth();
    const _auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as CustomizedState;
    
    let from = state?.from?.pathname || '/';

    const handleChange = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
        setUser({...user, [name]: value});
        setError("");
    }

    const handleGoogle = async () => {
        await loginWithGoogle();
    }

    const handleFacebook = async () => {
        await loginWithFacebook();
    }

    const handleApple = async () => {
        await loginWithApple();
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (user.password === user.verifyPassword) {
                await signup(user.email, user.password);
                if (auth.currentUser) 
                    await updateProfile(auth.currentUser, {
                        displayName: user.name + " " + user.lastName
                    });
                navigate(from, { replace: true });
            }
            else setError(t("not-same-password"));
        } catch (error) {
            if (error instanceof FirebaseError) {
                setError(error.message); //works
            } else {
                console.log(error); // this could still happen
            }
        }
    }

    if (loading) return <Loading/>

    if (_auth.user) return <Navigate to="/cases" replace/>

    return (
        <div className="login-container">
            <form className="login" onSubmit={handleSubmit}>
                <h1 className="login-title">{t("welcome")}</h1>
                <div className="login-subtitle">
                    {t("before-start") + " "}
                    <A className="green-link" href="/login">{t("here")}</A>
                </div>
                <div className="flex expandw gap15">
                    <input 
                        className="login-input flex-fill"
                        placeholder={t("name")}
                        type="text"
                        name="name" 
                        id="name"
                        onChange={handleChange}
                        required/>
                    <input 
                        className="login-input flex-fill"
                        placeholder={t("lastName")}
                        type="text"
                        name="lastName" 
                        id="lastName"
                        onChange={handleChange}
                        required/>
                </div>
                <div className="flex expandw">
                    <input 
                        className="login-input flex-fill"
                        placeholder={t("email")}
                        type="email" 
                        name="email" 
                        id="email"
                        onChange={handleChange}
                        required/>
                </div>
                <div className="flex expandw gap15">
                    <input 
                        className="login-input flex-fill"
                        placeholder={t("password")}
                        type="password"
                        name="password" 
                        id="password"
                        onChange={handleChange}
                        required/>
                    <input 
                        className="login-input flex-fill"
                        placeholder={t("verifyPassword")}
                        type="password"
                        name="verifyPassword" 
                        id="verifyPassword"
                        onChange={handleChange}
                        required/>
                </div>
                <div className="input-error">{error}</div>
                <button className="button-purple expandw login-button">{t("signup")}</button>
                <div className="flex expandw gap15" style={{"marginTop": "30px"}}>
                    {/*<div onClick={handleGoogle} className="social-login-button flex-fill">
                        <img src={process.env.PUBLIC_URL + "/img/google.png"}/>
                    </div>
                    <div onClick={handleFacebook} className="social-login-button flex-fill">
                        <img src={process.env.PUBLIC_URL + "/img/face.png"}/>
                    </div>
                    <div onClick={handleApple} className="social-login-button flex-fill">
                        <img src={process.env.PUBLIC_URL + "/img/apple.png"}/>
                    </div>*/}
                </div>
            </form>
        </div>
    );
}

export default Signup;