import { addDoc, collection, doc, getDoc, getDocs, query, Timestamp, updateDoc } from "firebase/firestore";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import MultiTextInput from "../components/input/MultiTextInput";
import A from "../components/navigation/A";
import { Case, Category, Question, View } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import { useLanguage } from "../context/LanguageProvider";
import "./styles/Editor.css"

type FileForm = {
    thumbnail: File
}

enum OptionType {
    String,
    Integer,
    Float
}

function CaseEditor() {
    const [cases, setCases] = useState<Case[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [caseForm, setCaseForm] = useState<Case>();
    const [selectedCaseIndex, setSelectedCaseIndex] = useState(0);
    const [done, setDone] = useState(false);
    const { t } = useTranslation();
    const [files, setFiles] = useState<FileForm>();
    const [language] = useLanguage();
    const fileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFiles(files => { return { ...files!, [e.target.name]: e.target.files![0] } });
    };
    const { i18n } = useTranslation();
    const { user } = useAuth();

    const downloadMainData = async () => {
        const casesQuery = query(collection(db, "cases"));
        const categoryQuery = query(collection(db, "categories"));
        const casesSnapshot = await getDocs(casesQuery);
        const categoriesSnapshot = await getDocs(categoryQuery);
        const downloadedCases = casesSnapshot.docs.map(doc => { return doc.data() as Case });
        const downloadedCategories = categoriesSnapshot.docs.map(doc => { return doc.data() as Category });
        setCases(downloadedCases);
        setCategories(downloadedCategories);
        setDone(true);
    }

    useEffect(() => {
        if (!done) downloadMainData();
    }, [done])

    useEffect(() => {
        if (cases.length > 0) {
            let potentialCaseForm = cases[selectedCaseIndex];
            if (potentialCaseForm.quest) {
                if (!potentialCaseForm.quest?.indications) potentialCaseForm.quest!.indications = [];
                if (!potentialCaseForm.quest?.questions) potentialCaseForm.quest!.questions = [];
            }
            else potentialCaseForm.quest = { indications: [], questions: [] }

            setCaseForm(potentialCaseForm);
        }
    }, [cases, selectedCaseIndex])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            /*if (files?.thumbnail) {
                const iconUri = await uploadImage(files?.thumbnail, "Icons/Categories", caseForm!.id);
                setCaseForm(_case => {return {..._case!, thumbnail: iconUri}});
                await updateDoc(doc(db, "categories", caseForm!.id), {...caseForm, thumbnail: iconUri});
            }
            else */await updateDoc(doc(db, "cases", caseForm!.id), caseForm);
            setDone(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        setCaseForm(form => { return { ...form!, [e.target.name]: e.target.checked } });
    }

    const handleOptionChange = (questionIndex: number, answerIndex: number) => {
        setCaseForm(form => {
            let newQuestions = form?.quest?.questions;
            newQuestions![questionIndex].correct = answerIndex;
            return { ...form!, quest: { ...form?.quest, questions: newQuestions } }
        })
    }

    const handleViewChange = (index: number, type: OptionType) => (e: ChangeEvent<HTMLInputElement>) => {
        let newViews = caseForm?.views;
        let newValue = undefined;
        switch (type) {
            case OptionType.String: newValue = e.target.value; break;
            case OptionType.Integer: newValue = parseInt(e.target.value); break;
            case OptionType.Float:
                if (e.target.value !== "") newValue = Math.abs(parseFloat(e.target.value));
                break;
        }
        newViews![index] = { ...newViews![index], [e.target.name]: newValue }
        setCaseForm(form => { return { ...form!, views: newViews! } });
    }

    const handleViewCheckbox = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
        let newViews = caseForm?.views;
        newViews![index] = { ...newViews![index], [e.target.name]: e.target.checked }
        setCaseForm(form => { return { ...form!, views: newViews! } });
    }

    const handleViewVisibility = (index: number) => () => {
        let newViews = caseForm?.views;
        newViews![index] = { ...newViews![index], visibility: !newViews![index].visibility }
        setCaseForm(form => { return { ...form!, views: newViews! } });
    }

    const addIndication = () => {
        setCaseForm(form => { return { ...form!, quest: { ...form?.quest, indications: [...form?.quest?.indications!, { en: "", es: "" }] } } })
    }

    const addQuestion = () => {
        const emptyQuestion: Question = { answers: [{ en: "", es: "" }], correct: 0, question: { en: "", es: "" } };
        setCaseForm(form => { return { ...form!, quest: { ...form?.quest, questions: [...form?.quest?.questions!, emptyQuestion] } } })
    }


    const addView = () => {
        const emptyView: View = { fragments: 6, speedMultiplier: 1, referenceUrl: "", thumbnail: "", unityKey: "", visibility: false, title: { en: "", es: "" }, pWidth: 830, pHeight: 830, width: 0, height: 0 };
        setCaseForm(form => { return { ...form!, views: [...form!.views, emptyView] } })
    }

    const addAnswer = (questionIndex: number) => () => {
        let newQuestions = caseForm?.quest?.questions;
        newQuestions![questionIndex] = { ...newQuestions![questionIndex], answers: [...newQuestions![questionIndex].answers, { en: "", es: "" }] }
        setCaseForm(form => {
            return { ...form!, quest: { ...form?.quest, questions: newQuestions } }
        })
    }

    const removeIndication = (indicationIndex: number) => () => {
        setCaseForm(form => {
            return {
                ...form!, quest: { ...form?.quest, indications: form?.quest?.indications!.filter((indication, index) => index !== indicationIndex) }
            }
        })
    }

    const removeQuestion = (questionIndex: number) => () => {
        setCaseForm(form => { return { ...form!, quest: { ...form?.quest, questions: form?.quest?.questions!.filter((question, index) => index !== questionIndex) } } })
    }

    const removeView = (viewIndex: number) => () => {
        setCaseForm(form => { return { ...form!, views: form!.views.filter((view, index) => index !== viewIndex) } })
    }

    const removeAnswer = (questionIndex: number, answerIndex: number) => () => {
        setCaseForm(form => {
            let newQuestions = form?.quest?.questions;
            newQuestions![questionIndex] = { ...newQuestions![questionIndex], answers: newQuestions![questionIndex].answers.filter((answer, index) => index !== answerIndex) }
            return { ...form!, quest: { ...form?.quest, questions: newQuestions } }
        })
    }

    const addCase = async () => {
        try {
            let emptyCase: Case = {
                id: "",
                created: Timestamp.now(),
                title: { en: "New Case", es: "Nuevo Caso" },
                hiddenTitle: { en: "", es: "" },
                description: { en: "", es: "" },
                descriptionPreview: { en: "", es: "" },
                diagnostic: { en: "", es: "" },
                category: "",
                thumbnail: "",
                visibility: false,
                views: [],
                quest: {
                    indications: [],
                    questions: []
                }
            }
            const docRef = await addDoc(collection(db, "cases"), emptyCase);
            await updateDoc(doc(collection(db, "cases"), docRef.id), { id: docRef.id });
            setDone(false);
        } catch (error) {
            console.log(error);
        }
    }

    const playCase = async () => {
        const snapshot = await getDoc(doc(db, "app", "unity"));
        window.open(`${(process.env.NODE_ENV === "development" ? snapshot.data()?.development : snapshot.data()?.production) || "https://play.fuve.app/old/index.html"}?userid=${user?.uid}&caseid=${caseForm?.id}&language=${i18n.language}`, "_blank");
    };

    if (!done) return <Loading />
    //console.log(caseForm?.quest?.questions);
    return (
        <>
            {caseForm && (
                <div className="limited-area">
                    <div className="centered-area" style={{ "flex": "1 2" }}>
                        <form onSubmit={handleSubmit} className="centered-area expandw flex-column" style={{ "gap": "20px" }}>
                            {t("Select case") + ":"}
                            <select value={selectedCaseIndex} className="flex-fill case-editor-select" onChange={(e) => setSelectedCaseIndex(e.target.selectedIndex)}>
                                {cases.sort((a, b) => a.title[language]!.localeCompare(b.title[language]!)).map((_case, index) => (
                                    <option key={_case.id} value={index}>{_case.title.es}</option>
                                ))}
                            </select>
                            <div onClick={addCase} className="button-purple limited-area" style={{ "maxWidth": "200px" }}>Add Case</div>
                            <div style={{ "display": "flex" }}>
                                <h2 style={{ "margin": "0", "flex": "1 2" }}>{t("Información y atributos")}</h2>

                            </div>
                            <div style={{ "display": "flex", "flexDirection": "row", "gap": "10px", "flexWrap": "wrap" }}>
                                <div>Estado del curso:</div>
                                <div style={{ "display": "flex", "flex": "1 2" }}>{caseForm.visibility ? <div style={{ "color": "#1FFF00" }}>Visible</div> : <div style={{ "color": "red" }}>Oculto</div>}</div>
                                <div style={{ "margin": "0", "marginTop": "0" }}>ID del caso: <A className="green-link" onClick={playCase}>{caseForm.id}</A></div>
                            </div>
                            <div style={{ "display": "flex", "flexDirection": "row", "gap": "10px", "flexWrap": "wrap" }}>
                                <div>{t("Category") + ": " + categories.find(category => category.id === caseForm.category)?.title[language]}</div>
                            </div>

                            <div className="case-editor-multi">
                                <div className="flex-column flex-fill case-editor-input-area" style={{ "justifyContent": "center" }}>
                                    <div className="flex-fill flex">
                                        <div className="flex-fill flex-column">
                                            <div style={{"display": "flex", "gap": "15px"}}>
                                                <div>
                                                    <div className="button-purple limited-area" style={{ "width": "100px" }} onClick={() => setCaseForm(form => { return { ...form!, visibility: !form?.visibility } })}>
                                                        {!caseForm.visibility
                                                            ? <>Publicar</>
                                                            : <>Archivar</>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="button-purple limited-area" style={{ "width": "100px" }} onClick={() => setCaseForm(form => { return { ...form!, lite: !form?.lite } })}>
                                                        {!caseForm.lite
                                                            ? <>Lite</>
                                                            : <>NO Lite</>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div>
                                                <label>{t("Thumbnail") + ": "}</label>
                                                <input type={"file"} accept="image/jpeg,image/png" name="thumbnail" onChange={fileChangeHandler} />
                                            </div>
                                        </div>
                                        <img src={caseForm.thumbnail} style={{ "width": "120px", "height": "120px" }} />
                                    </div>

                                    <div>{t("Category")}</div>
                                    <select value={caseForm.category} onChange={(e) => setCaseForm(form => { return { ...form!, category: e.target.value } })} className="flex-fill case-editor-select" style={{ "maxHeight": "30px" }}>
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>{category.title[language]}</option>
                                        ))}
                                    </select>
                                </div>
                                <MultiTextInput value={caseForm.title} field="title" setForm={setCaseForm} />
                            </div>
                            <div className="case-editor-multi">
                                <MultiTextInput value={caseForm.hiddenTitle} field="hiddenTitle" setForm={setCaseForm} />
                                <MultiTextInput value={caseForm.descriptionPreview} field="descriptionPreview" setForm={setCaseForm} />
                            </div>
                            <MultiTextInput value={caseForm.description} field="description" setForm={setCaseForm} useTextArea />
                            <MultiTextInput value={caseForm.diagnostic} field="diagnostic" setForm={setCaseForm} useTextArea />
                            <h2 style={{ "margin": "0" }}>{t("Views")}</h2>
                            {caseForm.views && caseForm.views.map((view, viewIndex) => (
                                <div key={"question-" + viewIndex}>
                                    <h4 style={{ "margin": "10px 0" }}>{t("View") + " " + (viewIndex + 1)}</h4>
                                    <div className="flex-column flex-fill case-editor-input-area" style={{ "justifyContent": "center" }}>
                                        <div className="flex-fill flex" style={{ "gap": "5px", "marginLeft": "15px" }}>
                                            Estado:
                                            <div style={{ "display": "flex", "flex": "1 2" }}>{caseForm.views[viewIndex].visibility ? <div style={{ "color": "#1FFF00" }}> Visible</div> : <div style={{ "color": "red" }}> Oculto</div>}</div>
                                        </div>
                                        <MultiTextInput
                                            removeButton={<img onClick={removeView(viewIndex)} style={{ "width": "15px", "height": "15px" }} src={process.env.PUBLIC_URL + "/img/x-icon.png"} />}
                                            value={view.title} field="views" arrayField="title" index={viewIndex} setForm={setCaseForm} />
                                        <div className="flex flex-fill case-editor-input-area gap15">
                                            <div className="flex-column flex-fill gap15">
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Fragments") + ":"}
                                                    <input value={view.fragments} type={"number"} name="fragments" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Integer)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Pixel Width") + ":"}
                                                    <input value={view.pWidth || ""} type={"number"} name="pWidth" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Float)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Pixel Height") + ":"}
                                                    <input value={view.pHeight || ""} type={"number"} name="pHeight" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Float)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Width") + ":"}
                                                    <input value={view.width || ""} type={"number"} name="width" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Float)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Height") + ":"}
                                                    <input value={view.height || ""} type={"number"} name="height" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Float)} />
                                                </div>
                                            </div>
                                            <div className="flex-column flex-fill">
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Unity Key") + ":"}
                                                    <input value={view.unityKey} name="unityKey" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.String)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Volume Speed") + ":"}
                                                    <input value={view.speedMultiplier} name="speedMultiplier" type={"number"} className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Float)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px", "marginTop": "10px" }}>
                                                    {t("Doppler Key") + ":"}
                                                    <input value={view.dopplerKey || ""} name="dopplerKey" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.String)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Doppler Speed") + ":"}
                                                    <input value={view.dopplerSpeedMultiplier} type={"number"} name="dopplerSpeedMultiplier" className="flex-fill case-editor-select" onChange={handleViewChange(viewIndex, OptionType.Float)} />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Reference") + ":"}
                                                    <input type={"file"} name="reference" />
                                                </div>
                                                <div className="flex" style={{ "alignItems": "center", "gap": "10px" }}>
                                                    {t("Thumbnail") + ":"}
                                                    <input type={"file"} name="thumbnail" />
                                                </div>
                                                <br></br>
                                                <div className="button-purple limited-area" style={{ "width": "100px" }} onClick={handleViewVisibility(viewIndex)}>
                                                    {!caseForm.views[viewIndex].visibility
                                                        ? <>Publicar</>
                                                        : <>Ocultar</>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="editor-add-button" onClick={addView}>{t("Add View")}</div>
                            <h2 style={{ "margin": "0" }}>{t("Quest")}</h2>
                            <h3 style={{ "margin": "0" }}>{t("Indications")}</h3>
                            {caseForm.quest?.indications?.map((indication, indicationIndex) => (
                                <MultiTextInput
                                    removeButton={<img onClick={removeIndication(indicationIndex)} style={{ "width": "15px", "height": "15px" }} src={process.env.PUBLIC_URL + "/img/x-icon.png"} />}
                                    key={indicationIndex} value={indication} field="quest" subfield="indications" index={indicationIndex} setForm={setCaseForm} />
                            ))}
                            <div className="editor-add-button" onClick={addIndication}>{t("Add Indication")}</div>
                            <h3 style={{ "margin": "0" }}>{t("Questions")}</h3>
                            {caseForm.quest?.questions?.map((question, questionIndex) => (
                                <div key={"question-" + questionIndex}>
                                    <h4 style={{ "margin": "10px 0" }}>{t("Question") + " " + (questionIndex + 1)}</h4>
                                    <div className="flex-column flex-fill case-editor-input-area" style={{ "justifyContent": "center" }}>
                                        <MultiTextInput
                                            removeButton={<img onClick={removeQuestion(questionIndex)} style={{ "width": "15px", "height": "15px" }} src={process.env.PUBLIC_URL + "/img/x-icon.png"} />}
                                            value={question.question} field="quest" subfield="questions" subArrayField="question" index={questionIndex} setForm={setCaseForm} />
                                        <MultiTextInput value={question.explanation || { "en": "", "es": "" }} field="quest" subfield="questions" subArrayField="explanation" index={questionIndex} setForm={setCaseForm} useTextArea />
                                        {question.answers.map((answer, answerIndex) => (
                                            <div key={"answer-" + answerIndex}>
                                                <h5 style={{ "margin": "10px 0" }}>{t("Answer") + " " + (answerIndex + 1)}</h5>
                                                <div className="flex">
                                                    <input
                                                        type={"radio"}
                                                        name={"answers-" + questionIndex + "-answers"}
                                                        value={answerIndex}
                                                        checked={question.correct === answerIndex}
                                                        onChange={() => handleOptionChange(questionIndex, answerIndex)}
                                                    />
                                                    <MultiTextInput
                                                        removeButton={<img onClick={removeAnswer(questionIndex, answerIndex)} style={{ "width": "15px", "height": "15px" }} src={process.env.PUBLIC_URL + "/img/x-icon.png"} />}
                                                        value={answer} field="quest" subfield="questions" subArrayField="answers" index={questionIndex} subIndex={answerIndex} setForm={setCaseForm} />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="editor-add-button" onClick={addAnswer(questionIndex)}>{t("Add Answer")}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="editor-add-button" onClick={addQuestion}>{t("Add Question")}</div>
                            <button className="button-purple">Submit</button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default CaseEditor;