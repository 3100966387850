import './styles/TermsPrivacy.css';

function TermsPrivacy() {

    return (
        <div className="terms-area">
            <h1> Términos y Condiciones de Uso.</h1>
            <div className='terms-content'>
                <p style={{ "fontSize": "20px" }}>Aquellas personas que naveguen en la página y/o Landing Page: <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a> y/o <a href='https://play.fuve.app/' target="_blank"><p className='green-link'>http://play.fuve.app/</p></a> en adelante, <span style={{ "fontWeight": "700" }}>"LA APP"</span> , ya sean Usuarios de Periodo a Prueba o Usuarios Premium, aceptan los presentes Términos y Condiciones de Uso y se comprometen a respetarlos.</p>
                <p style={{ "fontSize": "20px" }}>Al ingresar y utilizar este portal de Internet, cuyo nombre de dominio es <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a> más sus derivados descritos en “Definiciones”, los cuales son propiedad de <span style={{ "fontWeight": "700" }}>FUVE INC</span>, que en lo sucesivo se denominará <span style={{ "fontWeight": "700" }}>"FUVE"</span>, el Usuario está aceptando los <span style={{ "fontWeight": "700" }}>TÉRMINOS Y CONDICIONES DE USO</span> contenidos en este convenio y declara expresamente su aceptación utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 del Código Civil Federal para los Estados Unidos Mexicanos.</p>
                <p style={{ "fontSize": "20px" }}>En caso de no aceptar en forma absoluta y completa los términos y condiciones de este convenio, el Usuario deberá abstenerse de acceder, utilizar y observar <span style={{ "fontWeight": "700" }}>LA APP.LA APP</span> es una aplicación diseñada para explorar e interactuar con casos clínicos exclusivos de centros reconocidos a nivel mundial para aprender de forma práctica e interactiva desde la comodidad de un consultorio o casa, sin la necesidad de pacientes o equipo adicional.</p>
                <p style={{ "fontSize": "20px" }}>Los Términos y Condiciones Generales de Uso pueden ser consultados on-line. <span style={{ "fontWeight": "700" }}>FUVE</span> se reserva el derecho a modificarlos o actualizarlos en todo momento, sin otra formalidad necesaria que su inclusión on-line. Solo la última versión on-line que esté en el momento de los hechos en cuestión, serán aplicables a las partes.</p>
                <div className='terms-title'>DEFINICIONES</div>
                <ol style={{ "display": "flex", "flexDirection": "column", "gap": "15px" }}>
                    <li>“Términos” son estos Términos y Condiciones de Uso.</li>
                    <li>“Casos” / ”Casos clínicos” se refiere a los casos clínicos que serán presentados como contenido de la APP, contenido principal de la misma.</li>
                    <li>“Datos” significa cualquier y toda información recolectada durante el uso de la APP.</li>
                    <li>“Dispositivo” significa un dispositivo electrónico utilizado para acceder a los Servicios, tal como una Computadora y/o Móvil.</li>
                    <li>“Internet” significa la red informática mundial que comprende redes interconectadas por protocolos normalizados de transmisión de datos entre ordenadores; concretamente son los protocolos estándar TCP/IP de Internet, incluidos los estándares para Código HTML;</li>
                    <li><span style={{ "fontWeight": "700" }}>“Plataforma” / “APP FUVE” / “APP”</span> significa colectivamente, el conjunto de archivos electrónicos, aplicaciones web como móviles y páginas web referentes a <span style={{ "fontWeight": "700" }}>LA APP</span><a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a>, <a href='http://play.fuve.app/' target="_blank"><p className='green-link'>http://play.fuve.app/</p></a> . <span style={{ "fontWeight": "700" }}>EL URL</span> <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a>; la Cloud de google así como cualquier versión de software de la app <span style={{ "fontWeight": "700" }}>FUVE</span>, corriendo en cualquier plataforma y cualquier sitio web relacionado (incluyendo las versiones optimizadas móviles de <span style={{ "fontWeight": "700" }}>LA APP</span>) incluyendo cualquier actualización que surja, así como cualquier otro software o documentación que permita el uso de LA APP.</li>
                    <li>“Servicios” significa los servicios actualmente ofrecidos al Usuario de <span style={{ "fontWeight": "700" }}>LA APP</span> o de aquí en adelante en cualquier momento desarrollado y ofrecido al Usuario desde LA APP.</li>
                    <li>“Usuario(s)” es una persona física que se registra/suscribe en <span style={{ "fontWeight": "700" }}>LA APP</span> y lo utiliza (Incluyendo el de Periodo de Prueba y Premium).</li>
                    <li>“Usuario(s) en Periodo de prueba” es una persona física que se registra en <span style={{ "fontWeight": "700" }}>LA APP</span> y lo utiliza por un periodo determinado y limitado sin costo.</li>
                    <li>"Usuario(s) Premium” es una persona física o moral que se suscribe en <span style={{ "fontWeight": "700" }}>LA APP</span> y lo utiliza, tiene dicha obligación del pago de los servicios.</li>
                    <li>“Administrador(es)” es la persona física o moral que controla el Panel de Control o de Administración la cual es la herramienta desde donde gestionaremos <span style={{ "fontWeight": "700" }}>LA APP</span>.</li>
                </ol>
                <div className='terms-title'>ARTÍCULO 1- PARTES</div>
                <ol style={{ "display": "flex", "flexDirection": "column", "gap": "15px" }}>
                    <li>FUVE es el negocio comercial que proporciona LA APP. FUVE INC, mejor conocido como FUVE, con domicilio en 405 8th, Del Mar, CA, US 92014, y portal de internet https://www.fuve.app/,http://play.fuve.app/ o su considerada Plataforma.</li>
                    <li>Usuario(s) en Periodo de prueba</li>
                    <li>Usuario(s) Premium</li>
                </ol>
                <div className='terms-title'>ARTÍCULO 2- OBJETO</div>
                <p style={{ "fontSize": "20px" }}>Los Términos y Condiciones de Uso tienen por objeto definir los términos, formas y condiciones de uso de los servicios disponibles en <span style={{ "fontWeight": "700" }}>LA APP</span>. Igualmente, informan a los Usuarios sobre las modalidades de suscripción y las condiciones de uso de <span style={{ "fontWeight": "700" }}>LA APP</span> y del servicio suscrito. FUVE pone a disposición de los Usuarios una plataforma y diferentes servicios con el objetivo de facilitar el acceso a los servicios para explorar e interactuar con casos clínicos exclusivos de centros reconocidos a nivel mundial para aprender de forma práctica e interactiva, desde cualquier lugar, sin la necesidad de pacientes o equipo médico.</p>
                <div className='terms-title'>ARTÍCULO 3- REGISTRO Y SUSCRIPCIÓN A LA APP</div>
                <ol style={{ "display": "flex", "flexDirection": "column", "gap": "15px" }}>
                    <li>El usuario deberá ingresa a <span style={{ "fontWeight": "700" }}>FUVE Web App</span> <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>https://www.fuve.app/</p></a> o bien descargar la aplicación de FUVE en el celular desde la <span style={{ "fontWeight": "700" }}>Apple Store</span> o <span style={{ "fontWeight": "700" }}>Play Store</span></li>
                    <li>Una vez que se ingresa a la App, deberá crearse la cuenta y visualizar la biblioteca de casos clínicos y seleccionar el caso clínico que se desea explorar.</li>
                    <li>Se deberá ingresar desde la computadora a <a href='https://wwww.fuve.app/premium' target="_blank"><p className='green-link'>fuve.app/Premium</p></a> para vincular la cuenta.</li>
                    <li>El Usuario deberá suscribirse a <span style={{ "fontWeight": "700" }}>FUVE Premium</span> para obtener acceso a casos clínicos.</li>
                    <li>Se deberá sincronizar la computadora y el celular, y explorar los casos.</li>
                    <li>El Usuario podrá identificar la patología, emitir el diagnóstico y compararlo con el de los expertos.</li>
                </ol>
                <div className='terms-title'>Suscripción a FUVE Premium</div>
                <p style={{ "fontSize": "20px" }}>Para acceder a todos los casos clínicos de la APP, es necesario ser un usuario premium, lo cual incluye el acceso a explorar, diagnosticar y calificar un nuevo caso cada semana. Para convertirse en usuario premium es posible realizarlo vía la aplicación o vía el sitio web <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>www.fuve.app</p></a></p>
                <p style={{ "fontSize": "20px" }}>Si se ingresa a <a href='https://www.fuve.app/' target="_blank"><p className='green-link'>www.fuve.app</p></a>, se encontrará el acceso para suscribirse, el cual mostrará los diferentes paquetes disponibles de suscripción para posteriormente direccionar la opción a pagar vía la plataforma de Stripe.</p>
                <p style={{ "fontSize": "20px" }}>Si se ingresa desde la aplicación, una vez que se haya probado el caso demo gratuito incluido, se presentará una pantalla que mostrará los diferentes paquetes disponibles para la suscripción, posteriormente se direccionará a la opción de pago vía App Store o Play Store donde se solicitará ingresar la contraseña para confirmar la compra.</p>
                <div className='terms-title'>Costo de suscripción</div>
                <p style={{ "fontSize": "20px" }}>El costo de la suscripción a la app FUVE dependerá de la modalidad elegida por el Usuario:</p>
                <ol style={{ "display": "flex", "flexDirection": "column", "gap": "15px" }}>
                    <li>Suscripción mensual USD$119 al mes</li>
                    <li>Suscripción trimestral USD$299 al mes</li>
                    <li>Suscripción anual USD$999 al año</li>
                </ol>
                <p style={{ "fontSize": "20px" }}>Los cargos se realizarán de acuerdo al tipo de cambio y de conformidad con la institución bancaria con la cual se realice el método de pago en el día de la transacción. Este costo incluye el uso y acceso a los casos clínicos de la aplicación durante un periodo seleccionado.</p>
                <div className='terms-title'>Renovación automática de suscripción</div>
                <p style={{ "fontSize": "20px" }}>Una vez que se haya cumplido el plazo de suscripción, <span style={{ "fontWeight": "700" }}>FUVE</span> realizará la renovación automática al mismo medio de pago que haya indicado en su primera suscripción y por la misma temporalidad elegida. El usuario podrá cambiar de suscripción en el momento que deseé.</p>
                <div className='terms-title'>Suscripción como Usuario en Periodo de Prueba</div>
                <p style={{ "fontSize": "20px" }}>La suscripción en periodo a prueba a <span style={{ "fontWeight": "700" }}>LA APP</span> solo estará disponible solo y durante eventos o congresos de lanzamiento y/o promoción de la APP FUVE y para participantes de dichos eventos. Dicho periodo a prueba solo contendrá con 1 caso gratuito, seleccionados por FUVE.</p>
                <p style={{ "fontSize": "20px" }}>Todo Usuario en Periodo de Prueba puede suscribirse al servicio, convirtiéndose en un Usuario Premium. El periodo de prueba se limita al tiempo que duren los eventos promocionales de la APP, posterior a éste, el Usuario deberá suscribirse a <span style={{ "fontWeight": "700" }}>FVUE Premium</span> o de lo contrario perderá acceso al contenido de <span style={{ "fontWeight": "700" }}>LA APP</span>. Durante ese periodo, se pueden ampliar o modificar a discreción de FUVE, por razón de alguna promoción o beneficio exclusivo para ciertos Usuarios indicados por FUVE.</p>
                <div className='terms-title'>ARTÍCULO 4- MATERIAL PUBLICITARIO</div>
                <p style={{ "fontSize": "20px" }}>El Usuario reconoce y acepta que <span style={{ "fontWeight": "700" }}>FUVE</span> es una organización independiente de terceros patrocinadores y anunciantes, por lo que <span style={{ "fontWeight": "700" }}>LA APP</span> se encuentra libre de material publicitario de terceros. Asimismo FUVE enviará de manera semanal o mensual notificaciones, boletines informativos, así como otros elementos publicitarios que considere importantes para los Usuarios sobre el contenido principal de <span style={{ "fontWeight": "700" }}>LA APP</span>, así como servicios adicionales ofrecidos por <span style={{ "fontWeight": "700" }}>FUVE</span>.</p>
                <p style={{ "fontSize": "20px" }}>Este material se encuentra protegido por las leyes que en materia de propiedad intelectual e industrial resulten aplicables.</p>
                <p style={{ "fontSize": "20px" }}>Las redes sociales que se manejan para la promoción y difusión de la app son: Facebook, Instagram, Linkedin, Youtube.</p>
                <div className='terms-title'>ARTÍCULO 5- ENLACES DE TERCERAS PARTES</div>
                <p style={{ "fontSize": "20px" }}>Cierto contenido y servicios disponibles pueden incluir material de terceras partes. Enlaces de terceras partes en esta Plataforma pueden redireccionarse a sitios web de terceras partes que no están afiliadas con <span style={{ "fontWeight": "700" }}>FUVE</span>, quien no se responsabiliza de examinar o evaluar el contenido o exactitud y no garantiza ni tendrá ninguna obligación o responsabilidad por cualquier material de terceros o sitios web, o de cualquier material, productos o servicios de terceros.</p>
                <p style={{ "fontSize": "20px" }}><span style={{ "fontWeight": "700" }}>FUVE</span> no se hace responsable de cualquier daño o daños relacionados con la adquisición o utilización de bienes, servicios, recursos, contenidos, o cualquier otra transacción realizadas en conexión con sitios web de terceros. El Usuario debe revisar cuidadosamente las políticas y prácticas de terceros y asegurarse de entenderlas antes de participar en cualquier transacción. Quejas, reclamos, inquietudes o preguntas con respecto a productos de terceros deben ser dirigidas a la tercera parte.</p>
                <p style={{ "fontSize": "20px" }}>Esta Plataforma puede facilitar enlaces o referencias de otros sitios web externos. <span style={{ "fontWeight": "700" }}>FUVE</span> declina cualquier responsabilidad respecto al contenido de estos otros sitios. Deberá tener en cuenta que la Política de Privacidad y Cookies de <span style={{ "fontWeight": "700" }}>FUVE</span> no es aplicable a la recogida y tratamiento de sus datos en estos otros sitios. FUVE no será responsable de ningún daño ni perjuicio de ninguna naturaleza derivado de su acceso, o incapacidad de acceso, a estos otros sitios y a la información que en ellos se facilita, ni de su uso de o confianza en cualquier Información facilitada en estos otros sitios. Los enlaces a estos otros sitios se facilitan únicamente para mayor comodidad de los Usuarios de <span style={{ "fontWeight": "700" }}>FUVE</span>.</p>
                <ol style={{ "display": "flex", "flexDirection": "column", "gap": "15px" }}>
                    <li>Uso de otros sitios/sistemas externos.</li>
                    <ol type="A">
                        <li>Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, FUVE utiliza los siguientes prestadores bajo sus correspondientes condiciones de privacidad.</li>
                        <br></br>
                        <ul>
                            <li>Redireccionamiento:</li>
                            <ol type="a">
                                <li>Por cobrar y/o Stripe son las plataformas para recibir pagos.</li>
                                <li>App Stores (App Store o Play Store)</li>
                            </ol>
                            <br></br>
                            <li>Software de análisis digital con fines de seguimiento</li>
                            <ol type="a">
                                <li>Google Analytics.</li>
                                <li>Tag manager</li>
                                <li>Facebook Pixel</li>
                                <li>Tidio</li>
                            </ol>
                            <br></br>
                            <li>Tecnologías de Seguimiento:</li>
                            <ol type="a">
                                <li>Almacenamiento Local</li>
                                <li>Cookies</li>
                            </ol>
                        </ul>
                        <br></br>
                        <li>En la Plataforma FUVE se estudian las preferencias de sus Usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen la comunidad y qué es lo que necesita.</li>
                        <br></br>
                        <li>El Usuario podrá establecer un hiperenlace o dispositivo técnico de enlace (por ejemplo, links o botones) desde su sitio web. El establecimiento del Hiperenlace no implica en ningún caso la existencia de relaciones entre FUVE y el propietario del sitio o de la página web en la que se establezca el Hiperenlace, ni la aceptación o aprobación por parte de FUVE de sus contenidos o servicios. En todo caso,FUVE se reserva el derecho de prohibir o inutilizar en cualquier momento cualquier Hiperenlace a la PlataformaFUVE. La Plataforma utiliza alguno de estos servicios: Geolocalización, Acceso a la cámara, Teléfono, Datos en segundo plano y Acceso a la galería de fotos, así como los siguientes:</li>
                        <br></br>
                        <ul>
                            <li>Leer contenido de almacenamiento compartido</li>
                            <li>Editar/eliminar contenido de almacenamiento compartido</li>
                            <li>Tener acceso completo a la red</li>
                            <li>Ver conexión de red</li>
                            <li>Controlar la vibración</li>
                            <li>Impedir que el teléfono entre en modo de suspensión</li>
                            <li>API Install Referrer de Play</li>
                            <li>Servicio de Facturación de Google Play</li>
                            <li>Recibir datos de Internet</li>
                        </ul>
                        <br></br>
                        <li>El Usuario podrá realizar a través de la APP lo siguiente:</li>
                        <br></br>
                        <ul>
                            <li>Ingresar sus datos de usuario</li>
                            <li>Determinar casos clínicos de interés</li>
                            <li>Proporcionar y capturar la información requerida en las formularios</li>
                            <li>Tomar fotografías y videos</li>
                            <li>Mandar correos personalizados determinados en la misma APP</li>
                            <li>Cualquier otro que FUVE considere necesario, de tiempo en tiempo, para prestar los Servicios.</li>
                        </ul>
                    </ol>
                </ol>
                <div className='terms-title'>ARTÍCULO 6- SOPORTE TÉCNICO</div>
                <br></br>
                <ol>
                    <li>Canales de comunicación entre los Usuarios y La APP.</li>
                    <br></br>
                    <ol type="A">
                        <li>Los Usuarios y <span style={{ "fontWeight": "700" }}>FUVE</span> para comunicarse podrán utilizar los canales habilitados en su página web, la cual derivará a una atención vía correo, chat de soporte directamente desde la aplicación web o por medio de las páginas web que se tienen.</li>
                        <li><span style={{ "fontWeight": "700" }}>LA APP</span> de <span style={{ "fontWeight": "700" }}>FUVE</span> no tiene la obligación de controlar, y en efecto no controla, los usos que los Usuarios puedan ejecutar sobre este módulo de comunicación, pero sí tendrá el derecho de cancelar la cuenta de cualquier Usuario que haya hecho un mal uso del canal. Asimismo, FUVE no está obligada a entablar comunicación con sus Usuarios mediante otras vías distintas a la mencionada.</li>
                        <li>El tiempo de atención a solicitudes será, si es por chat de soporte, un tiempo promedio de respuesta de 15 minutos; si es por correo de 24 horas.</li>
                    </ol>
                </ol>
                <p style={{ "fontSize": "20px" }}>La notificación deberá ser enviada vía correo electrónico a <span style={{ "fontWeight": "700" }}>soporte@medicinafetalmexico.com</span> en un horario de Lunes a Viernes de 9 am a 6 pm, por el grupo de soporte y atención al cliente para darle seguimiento.</p>
                <div className='terms-title'>ARTÍCULO 7- OTROS TÉRMINOS Y REGLAS, ENTRE OTROS.</div>
                <p style={{ "fontSize": "20px" }}>Estos Términos y Condiciones de Uso, son adicionales a cualquier término que pueda aplicarse a los Usuarios en virtud de acuerdos o términos de distribución, de licencia o de ventas, u otros acuerdos y términos vigentes. Si esta Plataforma o parte de ella contiene reglas, directrices, códigos de conducta, términos adicionales u otras instrucciones para los Usuarios, estos últimos se comprometen a cumplir también con esas reglas, directrices, etc. En caso de que surja cualquier conflicto o incongruencia, estos Términos son los que prevalecen. Si no está de acuerdo con alguno de estos Términos (o las otras reglas, directrices, etc.), no puede utilizar <span style={{ "fontWeight": "700" }}>LA APP FUVE</span>. Estos Términos pueden incluir disposiciones que contemplan funcionalidades o Servicios que no están (y no pretenden estar) disponibles en esta Plataforma. En caso de que alguna disposición de estos Términos solo se relacione con esas funcionalidades o Servicios, no tendrán ningún efecto o validez, y tampoco afectarán la validez de las demás disposiciones.</p>
                <div className='terms-title'>ARTÍCULO 8- DERECHO DE CANCELAR CUENTAS Y SUSCRIPCIONES.</div>
                <p style={{ "fontSize": "20px" }}>El Usuario Premium podrá cancelar su suscripción con derecho a reembolso dentro de los primeros 30 (treinta) días naturales siguientes a la adquisición de la misma. Para cancelar la suscripción el usuario deberá ponerse en contacto con el centro de ayuda de FUVE, mediante el mail <span style={{ "fontWeight": "700" }}>soporte@medicinafetalmexico.com</span>. El reembolso se efectuará dentro de los siguientes 5 (cinco) a 7 (siete) días hábiles de la solicitud de cancelación.</p>
                <p style={{ "fontSize": "20px" }}>Posterior a este periodo, El Usuario Premium podrá solicitar la cancelación de suscripción consciente de que no habrá reembolso por el pago ya realizado.</p>
                <p style={{ "fontSize": "20px" }}>La suspensión o cancelación del Plan y el derecho de utilizar la app de FUVE, no afectarán a los derechos u obligaciones legales de cualquiera de las partes.</p>
                <p style={{ "fontSize": "20px" }}>Considerando que existe la renovación automática de la suscripción anual, el Usuario sólo puede cancelar su cuenta y evitar la renovación, 30 (treinta) días antes de su fecha de corte (día de inicio de la suscripción), si no lo hace, se emitirá el cobro automático y la cancelación aplicará sólo dentro de los 30 días posteriores correspondientes.</p>
                <p style={{ "fontSize": "20px" }}>En cualquier momento, el Usuario puede cancelar su suscripción, vía correo electrónico al correo <span style={{ "fontWeight": "700" }}>soporte@medicinafetalmexico.com</span> en un horario de Lunes a Viernes de 9 am a 6 pm, horario de Ciudad de México (UTC-6).</p>
                <div className='terms-title'>ARTÍCULO 9- CONDICIONES DE PAGO</div>
                <ol style={{ "display": "flex", "flexDirection": "column", "gap": "15px" }}>
                    <li><span style={{ "fontWeight": "700" }}>FUVE</span> se reserva el derecho a modificar los precios en todo momento, y las suscripciones nuevas en función de la tarifa vigente. El precio de la suscripción anterior se respetará hasta su renovación de dicha suscripción y/o su renegociación.</li>
                    <li>En caso de que el cobro no pueda ser efectuado por cualquier circunstancia en el medio de pago otorgado por el Usuario, <span style={{ "fontWeight": "700" }}>FUVE</span> procederá a enviar notificación vía correo electrónico registrado por el Usuario, a través de la APP o cualquier otro medio formal (correo certificado) de los datos registrados por el Usuario, por su situación de impago, solicitando que actualice el medio de pago para proceder a realizar el cobro debido.</li>
                    <li>Igualmente, en caso de que el cobro no pueda ser efectuado, <span style={{ "fontWeight": "700" }}>FUVE</span> se reserva el derecho a suspender la ejecución de sus obligaciones (como de la cuenta) hasta que este pueda ser efectuado, sin ningún tipo de responsabilidad y sin que el Usuario o el Usuario Premium pueda pretender beneficiarse.</li>
                    <li>El tiempo de pago de la suscripción es anual, trimestral o mensual, de acuerdo a lo seleccionado por el Usuario.</li>
                    <li>Formas de pago: El medio de pago oficial será por medio de la domiciliación a tarjeta de crédito o débito o las opciones de pago que tenga la plataforma Stripe o App Stores disponibles.</li>
                    <li>El pago de la suscripción para tener acceso al contenido de la Plataforma es por anticipado, cuando <span style={{ "fontWeight": "700" }}>FUVE</span> reciba la confirmación del Usuario para suscribirse, se realizará el correspondiente cobro, el cual deberá cubrir el Usuario independientemente si usa o no la Plataforma.</li>
                </ol>
                <div className='terms-title'>ARTÍCULO 10- DISPONIBILIDAD DE LA APP FUVE.</div>
                <p style={{ "fontSize": "20px" }}>La Plataforma es accesible a todos aquellos Usuarios Premium que dispongan de acceso a internet. <span style={{ "fontWeight": "700" }}>FUVE</span> hace todos los esfuerzos razonables para que <span style={{ "fontWeight": "700" }}>LA APP</span> se encuentre disponible de manera permanente (24 horas al día, 7 días a la semana), pero no ofrece ninguna garantía de disponibilidad a los Usuarios o a los Usuarios Premium. <span style={{ "fontWeight": "700" }}>FUVE</span> no asumirá responsabilidad en caso de imposibilidad de acceso a la plataforma. <span style={{ "fontWeight": "700" }}>FUVE</span> se reserva el derecho a interrumpir la disponibilidad de la Plataforma con el fin de poder realizar las operaciones de mantenimiento necesarias para su buena administración (sábados y domingos de 12 am a 4 am).</p>
                <div className='terms-title'>ARTÍCULO 11- PROPIEDAD INTELECTUAL</div>
                <br></br>
                <ol>
                    <li>Contenidos de los Usuarios y de los Usuarios Premium:</li>
                    <ol type="A">
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> garantiza a los Usuarios y los Usuarios Premium que cuenta con las autorizaciones y licencia de todos los contenidos que proporciona, por lo que no vulneran ningún derecho en materia de propiedad intelectual o datos personales.</li>
                        <li>Los Usuarios y los Usuarios Premium asumen la responsabilidad de las consecuencias que se deriven de la difusión de los contenidos que se publican en la Plataforma <span style={{ "fontWeight": "700" }}>FUVE</span>.</li>
                    </ol>
                    <br></br>
                    <li>FUVE.</li>
                    <ol>
                        <li>La Plataforma de <span style={{ "fontWeight": "700" }}>FUVE</span> y los textos, fotografías, gráficos, imágenes, sonidos y videos que lo componen, son propiedad exclusiva de <span style={{ "fontWeight": "700" }}>FUVE</span> y/o de sus subcontratistas.</li>
                        <li>Las marcas “<span style={{ "fontWeight": "700" }}>FUVE</span>”, y los textos, gráficos, imágenes, sonidos y videos que lo componen son propiedad exclusiva de <span style={{ "fontWeight": "700" }}>FUVE</span> y/o de sus subcontratistas.</li>
                    </ol>
                </ol>
                <p style={{ "fontSize": "20px" }}>Cualquier copia o utilización no autorizada del contenido de <span style={{ "fontWeight": "700" }}>LA APP</span> o cualquier uso del contenido que no cumpla con estos Términos (o su espíritu) puede infringir los derechos de marca comercial, derechos de autor y otros derechos de propiedad intelectual exclusiva. Los Usuarios no deben utilizar el contenido de esta Plataforma (ni ningún otro contenido disponible en ella) de ninguna forma que infrinja los derechos de marca comercial, derechos de autor u otros derechos de propiedad intelectual. <span style={{ "fontWeight": "700" }}>FUVE</span> se reserva todos los derechos relacionados con el uso no autorizado o el incumplimiento de estos Términos</p>
                <div className='terms-title'>ARTÍCULO 12- TRATAMIENTO DE DATOS PERSONALES</div>
                <p style={{ "fontSize": "20px" }}>Conforme a la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES para los Estados Unidos Mexicanos, los Usuarios disponen de un derecho de acceder, modificar, rectificar, oponerse, cancelar, limitar el tratamiento, a la portabilidad; de los datos personales tratados que les concierne. Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, acceda al aviso de privacidad integral a través de nuestro portal de internet: <a href='https://www.fuve.app/Terms' target="_blank"><p className='green-link'>https://www.fuve.app/Terms</p></a></p>
                <div className='terms-title'>ARTÍCULO 13- RESPONSABILIDAD</div>
                <ol>
                    <li>Responsabilidad de FUVE</li>
                    <br></br>
                    <ol type="A">
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> es responsable de presentar casos clínicos y diagnósticos de los mismos realizados especialistas en la materia.</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no se hace responsable por la interpretación, o utilización de la información que los Usuarios realicen de los casos clínicos presentados.</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no participa en la relación del Usuario con su Paciente/Cliente.</li>
                        <br></br>
                        <li>La relación entre el Paciente y el Usuario Premium es totalmente independiente de <span style={{ "fontWeight": "700" }}>FUVE</span>.</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no se responsabiliza de los daños sufridos por los Usuarios, los Usuarios Premium o por un tercero que resulten directa o indirectamente del incumplimiento por los Usuarios o los Usuarios Premium de sus obligaciones en relación con las presentes condiciones generales de uso</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no asume responsabilidad por la utilización de la APP <span style={{ "fontWeight": "700" }}>FUVE</span> o del Servicio realizada con fines diferentes a los que aquí se establecen.</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no presta servicios de asesoramiento médico y los Usuarios Premium asumen, que si bien el contenido accesible se realiza y prepara por profesionales médicos para adaptarse lo máximo posible a los casos concretos, no supone garantía alguna, ni existe asesoramiento por parte de un profesional para la elaboración o redacción de los documentos en cuestión. Por ello, FUVEno es responsable del uso que los Usuarios y Usuarios Premium puedan hacer del contenido de la plataforma, ni de su completa actualización y utilidad a los usos previstos, o incorrecciones en la introducción de datos, etc., por los Usuarios o Usuarios Premium.</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no será responsable del uso que se haga de la información contenida en la Plataforma, de la información facilitada a los Usuarios o a los Usuarios Premium, ni de las interpretaciones de este contenido.</li>
                        <br></br>
                        <li><span style={{ "fontWeight": "700" }}>FUVE</span> no tiene ninguna obligación de involucrarse en conflictos que un Usuario tenga con otros, ni en incidentes en los que el usuario esté involucrado con otros Usuarios, o que se vean afectados por esta plataforma o se relacionen de cualquier forma con él.</li>
                    </ol>
                    <br></br>
                    <li>Responsabilidad de los Usuarios y de los Usuarios Premium:</li>
                    <br></br>
                    <ol type="A">
                        <li>El Usuario es el único responsable de la interpretación, o utilización de la información de los casos clínicos presentados.</li>
                        <br></br>
                        <li>Es importante destacar que el uso de FUVE es exclusivo para cada usuario Premium, es decir, que no se permite el uso de una misma cuenta por distintos usuarios. Ya que el contenido de la aplicación y de los casos clínicos es propio de FUVE.</li>
                        <br></br>
                        <li>En caso de que el Usuario no se siga las medidas establecidas, podrá ser revocado del uso de la aplicación (sin derecho a reembolso de la suscripción)</li>
                        <br></br>
                        <li>Los Usuarios y los Usuarios Premium son los únicos responsables de los daños directos o indirectos, materiales o intangibles, causados por ellos con ocasión de la utilización de la Plataforma y la interpretación que le den al contenido de la misma, en el entendido que son profesionales médicos con conocimientos previos adquiridos.</li>
                        <br></br>
                        <li>El Usuario no debe utilizar esta Plataforma ni los Servicios de ninguna manera que infrinja estos Términos (o su espíritu) o cualquier norma o legislación vigente (incluidas aquellas relacionadas con el uso de redes de comunicaciones públicas) o que cause, o pueda causar, algún daño, interrupción o problema en esta plataforma o los Servicios. Se le prohíbe al usuario acceder sin autorización a cualquier parte de esta plataforma, a cuentas de otros Usuarios o a cualquier sistema informático o redes conectadas a esta plataforma, ya sea por medio de hacking, recopilación de contraseñas o por cualquier otro medio. El Usuario no debe recopilar, ni intentar recopilar, información personal relacionada con otros Usuarios a través de la Plataforma.</li>
                        <br></br>
                        <li>Siempre que los Usuarios tengan la posibilidad de realizar negocios a través de esta plataforma (o cualquier otro vinculado) con un tercero (incluidos otros Usuarios o un proveedor de servicios o vendedor de productos) que no seaFUVE, ese negocio debe ser (y todos los derechos y obligaciones que surjan) sólo entre el Usuario y ese tercero.</li>
                        <br></br>
                        <li>El Usuario debe utilizar su Cuenta y los Servicios en estricto cumplimiento de la normativa nacional e internacional y del orden público.</li>
                    </ol>
                </ol>
                <p style={{ "fontSize": "20px" }}>Las limitaciones y exclusiones de responsabilidad en estos Términos se aplican independientemente de si la responsabilidad se basa en incumplimiento de contrato, perjuicios (negligencia o difamación inclusive), responsabilidad estricta, incumplimiento de garantías o cualquier otra teoría legal.</p>
                <div className='terms-title'>ARTÍCULO 14- EXCLUSIÓN DE GARANTÍAS: SERVICIOS Y CONTENIDO DE LA APP</div>
                <p style={{ "fontSize": "20px" }}>FUVE no ofrece ninguna Garantía de que las funciones o los Servicios disponibles a través de las interacciones de los Usuarios y otras interacciones con la Plataforma estén disponibles de forma continua, segura, sin interrupciones o sin errores, ni de que se corregirán los defectos, ni de que esta Plataforma o el servidor que lo pone a disposición estén libres de virus u otros componentes perjudiciales. FUVE y las empresas asociadas no se hacen responsables de ningún daño (o pérdida de datos) o virus que puedan infectar sus equipos informáticos u otras pertenencias como consecuencia de su acceso o uso de ésta, incluida la descarga de imágenes, software u otro contenido de la Plataforma. Si el uso del contenido de la Plataforma genera una necesidad de servicio, reparación o corrección del equipo o los datos, el Usuario asumirá todos los gastos relacionados con tal necesidad.</p>
                <ol>
                    <li>La información incluida en el contenido de FUVE (y en el contenido de Usuario) no constituye ningún asesoramiento jurídico, profesional, financiero o médico y no debe tomarse en forma literal. El contenido de LA APP que incluya declaraciones de proyecciones futuras, nuevas funcionalidades a desarrollar o declaraciones de intención no debe tomarse en forma literal. FUVE se reserva el derecho de no actuar de acuerdo con tales declaraciones.</li>
                </ol>
                <br></br>
                <div className='terms-title'>ARTÍCULO 15- INDEMNIZACIÓN</div>
                <br></br>
                <ol>
                    <li>El Usuario deberá indemnizar a FUVE, empresas asociadas y sus funcionarios, agentes, empleados y proveedores de servicios y liberarlos de toda responsabilidad con respecto a la pérdida, los procedimientos, los daños, los gastos (incluidos los costos y gastos legales razonables) o las responsabilidades contraídas por cualquiera de ellos que procedan de reclamaciones de terceros debido al incumplimiento o la infracción del Usuario de cualquier prohibición o restricción en sus actividades con respecto a esta Plataforma contenidas en estos Términos, así como de cualquier cometido, representación o garantía del Usuario que aparezca en estos Términos y en relación con reclamaciones de cualquier persona con respecto a asuntos, responsabilidades u obligaciones excluidas por FUVE en estos Términos.</li>
                    <br></br>
                    <li>Sin limitar el carácter general de la indemnización anterior, el Usuario libera, indemnizará y exonera a FUVE, las empresas asociadas y sus funcionarios, agentes, empleados y proveedores de servicios (que rechacen su responsabilidad) en relación con reclamaciones, responsabilidades, costos (incluidas tarifas jurídicas), gastos, demandas y daños (directos, indirectos o importantes) de cualquier naturaleza, ya sean conocidos o desconocidos, sospechados o no, revelados o sin revelar y que se hayan originado o estén relacionados con lo siguiente: cualquier contenido de Usuario prohibido o restringido cargado por el Usuario o cualquier uso que el Usuario le dé a esta Plataforma (incluido el uso prohibido o restringido del Usuario); cualquier pérdida, eliminación, supresión, corrupción o publicación de contenido de Usuario cargado por el Usuario; o cualquier uso no autorizado de la cuenta del Usuario o cualquier riesgo de la seguridad de la cuenta del Usuario por no mantener en secreto las contraseñas de la cuenta de Usuario u otros detalles de seguridad, y todas las situaciones relacionadas.</li>
                </ol>
                <br></br>
                <div className='terms-title'>ARTÍCULO 16- LEGISLACIÓN APLICABLE</div>
                <p style={{ "fontSize": "20px" }}>Estos Términos y Condiciones estarán sujetos a las leyes de los Estados Unidos Mexicanos. FUVEtrata de resolver cualquier desacuerdo rápida y eficientemente. Si el usuario no estuviera conforme con la manera en que se trate cualquier desacuerdo y quisiera interponer acciones legales, deberá hacerlo en la Ciudad de México, México.</p>
                <div className='terms-title'>ARTÍCULO 17- MODIFICACIONES DE LOS PRESENTES TÉRMINOS Y CONDICIONES</div>
                <p style={{ "fontSize": "20px" }}>FUVE se reserva el derecho de modificar en cualquier momento que considere oportuno el contenido de estos Términos y Condiciones de Uso, pudiendo adicionar provisiones relativas a áreas específicas o nuevos Servicios que en un futuro FUVE proporcione a través de LA APP, los cuales serán publicadas en la misma para su lectura y aceptación. Dichas modificaciones formarán parte integrante de los Términos y Condiciones para todos los efectos legales a que haya lugar. Es responsabilidad de los Usuarios verificar de tiempo en tiempo los presentes Términos y Condiciones para verificar el contenido de los mismos incluyendo cualquier cambio o modificación.</p>
                <p style={{ "fontSize": "20px" }}>Última actualización: 30 de Agosto de 2021.</p>
            </div>
        </div>
    );
}

export default TermsPrivacy;