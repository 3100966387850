import { FirebaseError } from "firebase/app";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/animations/Loading";
import A from "../components/navigation/A";
import { CustomizedState } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import "./styles/Signup.css"

function Login() {
    const [user, setUser] = useState({ email: "", password: "", verifyPassword: "", name: "", lastName: "" });
    const [error, setError] = useState("");
    const { t } = useTranslation();
    const auth = useAuth();
    const { login, loginWithApple, loginWithFacebook, loginWithGoogle, loading } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as CustomizedState;
    const [emailButton, setemmailButton] = useState(false);
    let from = state?.from?.pathname || '/';

    const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [name]: value });
        setError("");
    }

    const handleGoogle = async () => {
        await loginWithGoogle();
    }

    const clickEmmailButton = () => {
        if (emailButton == true) {
            setemmailButton(false);
        }
        else {
            setemmailButton(true);
        }
    }

    const handleFacebook = async () => {
        await loginWithFacebook();
    }

    const handleApple = async () => {
        await loginWithApple();
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await login(user.email, user.password);
            navigate(from, { replace: true });
        } catch (error) {
            if (error instanceof FirebaseError) {
                setError(error.message); //works
            } else {
                console.log(error); // this could still happen
            }
        }
    }

    if (loading) return <Loading />

    if (auth.user) return <Navigate to="/cases" replace />

    return (
        <div className="login-container">
            <div className="login">
                <h1 className="login-title" >{t("welcome")}</h1>
                <div className="flex expandw gap15" style={{ "marginTop": "30px", "flexDirection": "column", "gap": "15px" }}>
                    <div onClick={handleGoogle} className="social-login-button flex-fill" style={{ "borderRadius": "90px", "gap": "10px" }}>
                        <img src={process.env.PUBLIC_URL + "/img/google.png"} />
                        <div style={{ "color": "#5B5B5B", "fontWeight": "700" }}>{t("Sign in with Google")}</div>
                    </div>
                    {/*
                        <div onClick={handleFacebook} className="social-login-button flex-fill" style={{ "borderRadius": "90px" }}>
                        <img src={process.env.PUBLIC_URL + "/img/face.png"} />
                        </div>
                    */}
                    <div onClick={handleApple} className="social-login-button flex-fill" style={{ "borderRadius": "90px", "gap": "10px" }}>
                        <img src={process.env.PUBLIC_URL + "/img/apple.png"} />
                        <div style={{ "color": "#5B5B5B", "fontWeight": "700" }}>{t("Sign in with Apple")}</div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="login-title lineText">o</div>
                {emailButton ? (
                    <form className="login" onSubmit={handleSubmit}>
                        <div className="flex expandw">
                            <input
                                className="login-input flex-fill"
                                placeholder={t("email")}
                                type="email"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                required />
                        </div>
                        <div className="flex expandw">
                            <input
                                className="login-input flex-fill"
                                placeholder={t("password")}
                                type="password"
                                name="password"
                                id="password"
                                onChange={handleChange}
                                required />
                        </div>
                        <div className="login-subtitle expandw flex" style={{ "justifyContent": "flex-end" }}>
                            <A className="green-link" href="/passwordrecovery">{t("forgot-password")}</A>
                        </div>
                        <div className="input-error">{error}</div>
                        <button className="button-purple expandw login-button">{t("login")}</button>
                    </form>
                ) :
                <div onClick={clickEmmailButton} className="button-purple expandw login-button" style={{"display": "flex", "justifyContent": "center", "alignItems": "center"}}>{t("Log in with e-mail")}</div>
                }
                <br></br>
                <div className="login-subtitle">
                    {t("no-account") + " "}
                    <A className="green-link" href="/signup">{t("here")}</A>
                </div>
            </div>
        </div>
    );
}

export default Login;