import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import Loading from '../animations/Loading';

type props = {
    children: JSX.Element
}

export function RequireAdmin(props: props) {
  const location = useLocation();
  const { admin, loading } = useAuth();
  if (loading) return <Loading/>
  if (!admin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return props.children;
}