import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import A from "../components/navigation/A";

function Admin() {
    const { t } = useTranslation();
    return (
        <div className="limited-area">
            <div className="flex-fill centered-area">
                <div className="flex-fill flex-column gap15" style={{"justifyContent": "center", "alignItems": "center"}}>
                    <A href="/editor/cases" className="button-purple" style={buttonStyle}>{t("Cases")}</A>
                    <A href="/editor/categories" className="button-purple" style={buttonStyle}>{t("Categories")}</A>
                    <A href="/editor/sections" className="button-purple" style={buttonStyle}>{t("Sections")}</A>
                    <A href="/editor/volumes" className="button-purple" style={buttonStyle}>{t("Volumes")}</A>
                    <A href="/admin/users" className="button-purple" style={buttonStyle}>{t("Users")}</A>
                </div>
            </div>
        </div>
    );
}

const buttonStyle: CSSProperties = {
    width: "100%",
    maxWidth: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

export default Admin;