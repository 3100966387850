import axios from "axios"
import { Plan, Subscription } from "../components/types/CustomTypes";

const apiUri = process.env.REACT_APP_API;

export const getPayPalPlans = async (product_id?: string) : Promise<Plan[]> => {
    const params = product_id? ("?product_id=" + product_id) : "";
    const response =  await axios.get(apiUri + "/paypal/plans" + params);
    return response.data;
}

export const newPayPalSubscription = async (id: string, currentSubscription: Subscription) => {
    await axios.put(apiUri + "/subscriptions", {id, newSubscription: currentSubscription});
}