import { collection, doc, getDocs, query, Timestamp, updateDoc, where } from "firebase/firestore";
import { ChangeEvent, CSSProperties, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import { Subscription } from "../components/types/CustomTypes";
import { FUVE_User, useAuth } from "../context/AuthProvider";
import "./styles/Editor.css"

function UserEditor() {
    const [userQuery, setUserQuery] = useState("");
    const [user, setUser] = useState<FUVE_User>();
    const [done, setDone] = useState(true);
    const { t } = useTranslation();
    const auth = useAuth();

    const searchUser = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const userSnapshot = await getDocs(query(collection(db, "users"), where(userQuery.includes("@")? "email" : "uid", "==", userQuery)))
            if (!userSnapshot.empty)
            {
                setUser(userSnapshot.docs[0].data() as FUVE_User)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        setUser(user => { return { ...user!, [e.target.name]: e.target.checked } });
    }

    const handleSubscriptionSelect = (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
        let newSubscriptions = user?.subscriptions;
        let newValue = e.target.value;
        newSubscriptions![index] = { ...newSubscriptions![index], [e.target.name]: newValue }
        setUser(user => { return { ...user!, subscriptions: newSubscriptions! } });
    }

    const handleSubscriptionDate = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
        let newSubscriptions = user?.subscriptions;
        let newValue = Timestamp.fromDate(new Date(e.target.value));
        newSubscriptions![index] = { ...newSubscriptions![index], [e.target.name]: newValue }
        setUser(user => { return { ...user!, subscriptions: newSubscriptions! } });
    }

    const addSubscription = () => {
        const newSubscription: Subscription = {
            plan_name: "FUVE - Admin - Provided",
            next_billing_time: Timestamp.now(),
            product_id: "fuve-standard",
            provider: auth.user?.email || "admin",
            status: "EXPIRED",
            subscription_id: crypto.randomUUID()
        }
        let newSubscriptions = [...user?.subscriptions!, newSubscription];
        setUser(user => { return { ...user!, subscriptions: newSubscriptions! } });
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setDone(false);
            await updateDoc(doc(db, "users", user!.id), user);
            await searchUser(e);
            setDone(true);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (user && !user?.subscriptions) setUser(user => { return { ...user!, subscriptions: [] } })
    }, [user])

    if (!done) return <Loading/>

    return (
        <>
            <div className="limited-area">
                <div className="centered-area flex-column" style={{"flex": "1 2"}}>
                    <form onSubmit={searchUser} className="centered-area flex-column" style={{"gap": "20px"}}>
                        <h2>{t("Search user")}</h2>
                        <label>E-mail or UID</label>
                        <div className="flex gap15 flex-fill">
                            <input value={userQuery} onChange={e => setUserQuery(e.target.value)} style={{"flex": "1 2"}} className="case-editor-select"/>
                            <button>Search</button>
                        </div>
                    </form>
                    {user && (
                        done 
                        ? 
                            <form onSubmit={handleSubmit} className="centered-area flex-column" style={{"gap": "20px"}}>
                                <h2 style={{"marginBottom": "0"}}>{t("Currently editing")}</h2>
                                <div className="flex gap15 flex-fill">
                                    <label style={{"flex": "1 2"}}>{`E-mail: ${user?.email}`}</label>
                                    <label>{`UID: ${user?.id}`}</label>
                                </div>
                                <div className="flex gap15 flex-fill">
                                    <div className="case-editor-input-area flex-fill">
                                        <h3 style={header}>{t("Access control")}</h3>
                                        <div className="flex gap15 flex-fill" style={{"marginBottom": "10px", "alignItems": "center"}}>
                                            <label style={{"minWidth": "70px"}}>{`LITE: `}</label>
                                            <label className="switch">
                                                <input name="lite" checked={user.lite} onChange={handleCheckbox} type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="flex gap15 flex-fill" style={{"marginBottom": "10px", "alignItems": "center"}}>
                                            <label style={{"minWidth": "70px"}}>{`Premium: `}</label>
                                            <label className="switch">
                                                <input name="premium" checked={user.premium} onChange={handleCheckbox} type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="flex gap15 flex-fill" style={{"alignItems": "center"}}>
                                            <label style={{"minWidth": "70px"}}>{`Admin: `}</label>
                                            <label className="switch">
                                                <input name="admin" checked={user.admin} onChange={handleCheckbox} type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="case-editor-input-area flex-fill">
                                        <h3 style={header}>{t("Additional data")}</h3>
                                    </div>
                                </div>
                                <h2 style={header}>{t("Subscriptions")}</h2>
                                {user.subscriptions?.map((subscription, index) => (
                                    <div key={`subscription_${index}`} className="case-editor-input-area flex-fill">
                                        <h3 style={header}>{subscription.plan_name}</h3>
                                        <div>Provider: {subscription.provider}</div>
                                        <div>ID: {subscription.subscription_id}</div>
                                        <div>Status: {t(subscription.status)}</div>
                                        <div>
                                            <label>Product: </label> 
                                            <select value={subscription.product_id} name="product_id" onChange={handleSubscriptionSelect(index)} className="case-editor-select">
                                                <option>fuve-standard</option>
                                                <option>fuve-morphology</option>
                                                <option>fuve-premium</option>
                                            </select>
                                            <label style={{"marginLeft": "10px", "color": "gray"}}>* fuve-standard es el curso de patología</label>
                                        </div>
                                        <div>
                                            <label>Valid until: </label> 
                                            <input 
                                                name="next_billing_time" 
                                                value={subscription.next_billing_time.toDate().toISOString().slice(0, 19)} 
                                                onChange={handleSubscriptionDate(index)}
                                                type={"datetime-local"}/>
                                        </div>
                                    </div>
                                ))}
                                <div className="editor-add-button" onClick={addSubscription}>{t("Add subscription")}</div>
                                <button className="button-purple">Submit</button>
                            </form>
                        :
                            <Loading/>
                    )}
                </div>
            </div>
        </>
    );
}

const header: CSSProperties = {
    marginTop: 0,
    marginBottom: "10px"
}

export default UserEditor;