import './styles/Footer.css'
import A from "../navigation/A";
import { useTranslation } from "react-i18next";

function Footer() {
    const { t } = useTranslation();
    return (
        <div className="footer-layout">
        <div className="footer">
           <p style={{"color": "rgb(150, 150, 150)"}}>Copyright © 2022 |</p>
           <p style={{"color": "white"}}>💜</p>
           <p style={{"color": "rgb(150, 150, 150)"}}>{t("by")}</p>
           <p style={{"color": "white"}}>FUVE Inc.</p>
           <A className='green-link' href='/terms'>{t("Terms")}</A>
           <p>{t("&")}</p>
           <A className='green-link' href='/privacy'>{t("Privacy")}</A>
        </div>
    </div>
    );
}

export default Footer;