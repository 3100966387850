import en from './assets/translations/en.json'
import es from './assets/translations/es.json'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import { Layout } from './components/layout/Layout';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import AuthProvider from './context/AuthProvider';
import Cases from './pages/Cases';
import Signup from './pages/Signup';
import Contact from './pages/Contact';
import { RequireAuth } from './components/auth/RequireAuth';
import Pricing from './pages/Pricing';
import Payment from './pages/Payment';
import PasswordRecovery from './pages/PasswordRecovery';
import { RequireAdmin } from './components/auth/RequireAdmin';
import Transalator from './pages/Translator';
import { useLanguage } from './context/LanguageProvider';
import { useEffect } from 'react';
import Profile from './pages/Profile';
import CaseEditor from './pages/CaseEditor';
import CategoryEditor from './pages/CategoryEditor';
import Volumes from './pages/Volumes';
import Admin from './pages/Admin';
import Requirements from './pages/Requirements';
import SectionEditor from './pages/SectionEditor';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Courses from './pages/Courses';
import Course from './pages/Course';
import UsersMenu from './pages/UsersMenu';
import UserEditor from './pages/UserEditor';


function App() {
  const [ language ] = useLanguage();

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language, i18next])

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/passwordrecovery" element={<PasswordRecovery/>}/>
          <Route path="/courses/:sectionid" element={
            <RequireAuth>
              <Course/>
            </RequireAuth>
          }/>
          <Route path="/courses" element={
            <RequireAuth>
              <Courses/>
            </RequireAuth>
          }/>
          <Route path="/cases" element={
            <RequireAuth>
              <Cases/>
            </RequireAuth>
          }/>
          <Route path="/profile" element={
            <RequireAuth>
              <Profile/>
            </RequireAuth>
          }/>
          <Route path="/translator" element={
            <RequireAdmin>
              <Transalator/>
            </RequireAdmin>
          }/>
          <Route path="/editor/cases" element={
            <RequireAdmin>
              <CaseEditor/>
            </RequireAdmin>
          }/>
          <Route path="/editor/categories" element={
            <RequireAdmin>
              <CategoryEditor/>
            </RequireAdmin>
          }/>
          <Route path="/editor/sections" element={
            <RequireAdmin>
              <SectionEditor/>
            </RequireAdmin>
          }/>
          <Route path="/editor/volumes" element={
            <RequireAdmin>
              <Volumes/>
            </RequireAdmin>
          }/>
          <Route path="/editor/user" element={
            <RequireAdmin>
              <UserEditor/>
            </RequireAdmin>
          }/>
          <Route path="/admin" element={
            <RequireAdmin>
              <Admin/>
            </RequireAdmin>
          }/>
          <Route path="/admin/users" element={
            <RequireAdmin>
              <UsersMenu/>
            </RequireAdmin>
          }/>
          <Route path="/pricing" element={<Pricing/>}/>
          <Route path="/payment/:productType" element={
            <RequireAuth>
              <Payment/>
            </RequireAuth>
          }/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/requirements" element={<Requirements/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path='/privacy' element={<Privacy/>}/>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

i18next
    .use(initReactI18next)
    .init({
        resources: {
            en: {
              translation: en
            },
            es: {
              translation: es
            },
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    })

export default App;
