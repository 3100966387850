import { collection, doc, getDoc, getDocs, query, Timestamp, where } from "firebase/firestore";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { db } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import A from "../components/navigation/A";
import OverlayWindow from "../components/overlay/OverlayWindow";
import { CaseSummary, Category, Section } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import { useLanguage } from "../context/LanguageProvider";
import { useOverlay } from "../context/OverlayProvider";
import './styles/Cases.css'

function Courses() {
    const { i18n } = useTranslation();
    const { user, admin, lite, premium } = useAuth();
    const [, setOverlay] = useOverlay();
    const [sections, setSections] = useState<Section[]>([]);
    const [done, setDone] = useState(false);
    const { t } = useTranslation();
    const [language] = useLanguage();
    const navigate = useNavigate();

    const downloadMainData = async () => {
        let q = query(collection(db, "cases"), where("visibility", "==", true));
        if (admin) q = query(collection(db, "cases"));
        const sectionsSnapshot = await getDocs(collection(db, "sections"));
        setSections(sectionsSnapshot.docs.map(doc => { return doc.data() as Section }));
        setDone(true);
    }

    useEffect(() => {
        downloadMainData();
    }, [])

    if (!done) return <Loading/>

    return (
        <div className="limited-area">
            <div className="centered-area expandw flex-column">
                <h1>{t("Courses")}</h1>
                <div className="flex expandw gap15">
                    {sections.filter(section => (section.visibility || admin) && (section.id === "cn8rc85TNHwWnpjFcUQz" || section.id === "6jPqbIFShqacV65aI312")).map(section => (
                        <A href={`/courses/${section.id}`}>
                            <div style={mainSectionContainerStyle} key={section.id}>
                                <img style={{"width": "100%", "aspectRatio": "auto"}} src={section.thumbnail}/>
                                <div style={mainSectionTitleStyle}><div style={{"padding": "0 15px", "color": "white"}}>{section.title[language]}</div></div>
                            </div>
                        </A>
                    ))}
                </div>
                <h1>{t("Coming soon")}</h1>
                <div className="flex expandw gap15">
                    {sections.filter(section => (section.visibility || admin) && !(section.id === "cn8rc85TNHwWnpjFcUQz" || section.id === "6jPqbIFShqacV65aI312")).map(section => (
                        <A href={admin ? `/courses/${section.id}` : ""}>
                            <div style={sectionContainerStyle} key={section.id}>
                                <img style={{"width": "100%", "aspectRatio": "auto", opacity: "30%"}} src={section.thumbnail}/>
                                <div style={sectionTitleStyle}><div style={{"padding": "0 10px", "color": "white"}}>{section.title[language]}</div></div>
                            </div>
                        </A>
                    ))}
                </div>
            </div>
        </div>

    );
}

const mainSectionContainerStyle : CSSProperties = {
    flex: "1 2",
    maxHeight: "500px",
    "backgroundColor": "black", 
    "display": "flex",
    "justifyContent": "center",
    alignItems: "center",
    "overflow": "hidden",
    "position": "relative",
    borderRadius: "15px",
    "cursor": "pointer"
}

const mainSectionTitleStyle : CSSProperties = {
    "fontSize": "36px", 
    "fontWeight": "700", 
    "position": "absolute", 
    "bottom": "0",
    "backgroundColor": "#0000007D",
    width: "100%",
    height: "100px",
    "display": "flex",
    alignItems: "center",
}

const sectionContainerStyle : CSSProperties = {
    width: "355.55px", 
    height: "200px", 
    "backgroundColor": "black", 
    "display": "flex",
    "justifyContent": "center",
    alignItems: "center",
    "overflow": "hidden",
    "position": "relative",
    borderRadius: "15px",
    "cursor": "pointer"
}

const sectionTitleStyle : CSSProperties = {
    "fontSize": "24px", 
    "fontWeight": "700", 
    "position": "absolute", 
    "bottom": "0",
    "backgroundColor": "#0000007D",
    width: "100%",
    height: "50px",
    "display": "flex",
    alignItems: "center",
}

export default Courses;