import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import OverlayProvider from './context/OverlayProvider';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import LanguageProvider from './context/LanguageProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_CLIENT_ID || "", "currency": "MXN", "vault": true }}>
        <LanguageProvider>
          <OverlayProvider>
            <App />
          </OverlayProvider>
        </LanguageProvider>
      </PayPalScriptProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
