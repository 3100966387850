import axios from "axios"
import { Volume } from "../components/types/CustomTypes";

const apiUri = process.env.REACT_APP_API;

interface S3_Content {
    Key: "string"
}

export const getVolumes = async () : Promise<Volume[]> => {
    const response = await axios.get<string[]>(`${apiUri}/s3/folders`, {headers: {folder: "Volumes/"}});
    const volumes: Volume[] = response.data.map(volume => {return {
        id: volume,
        images: []
    }})
    return volumes;
}

export const getVolume = async (volume: Volume) : Promise<Volume> => {
    const response = await axios.get<S3_Content[]>(`${apiUri}/s3/folder/count`, {headers: {folder: volume.id}});
    return {id: volume.id, images: response.data.flatMap(content => {
        let url = content.Key.replace("Volumes/", "https://volumes.fuve.app/");
        return url;
    })};
}

export const setVolume = async () : Promise<Volume[]> => {
    const response = await axios.get<string[]>(`${apiUri}/s3/folders`, {headers: {folder: "Volumes/"}});
    const volumes: Volume[] = response.data.map(volume => {return {
        id: volume,
        images: []
    }})
    return volumes;
}

export const createVolume = async (volumeName: string) : Promise<void> => {
    await axios.post(`${apiUri}/s3/folders`, null, {headers: {folder: `Volumes/${volumeName}`}});
}