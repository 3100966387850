import { collection, doc, getDoc, getDocs, query, Timestamp, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { db } from "../backend/firebase";
import Collapsible from "../components/animations/Collapsible";
import OverlayWindow from "../components/overlay/OverlayWindow";
import { CaseSummary, Category, Section } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import { useLanguage } from "../context/LanguageProvider";
import { useOverlay } from "../context/OverlayProvider";
import './styles/Cases.css'

function Cases() {
    const { i18n } = useTranslation();
    const { user, admin, lite, premium } = useAuth();
    const [, setOverlay] = useOverlay();
    const [categories, setCategories] = useState<Category[]>([]);
    const [sections, setSections] = useState<Section[]>([]);
    const [cases, setCases] = useState<CaseSummary[]>([]);
    const [done, setDone] = useState(false);
    const { subscriptions } = useAuth();
    const { t } = useTranslation();
    const [language] = useLanguage();

    const downloadMainData = async () => {
        let q = query(collection(db, "cases"), where("visibility", "==", true));
        if (admin) q = query(collection(db, "cases"));
        const categoriesSnapshot = await getDocs(collection(db, "categories"));
        const sectionsSnapshot = await getDocs(collection(db, "sections"));
        const casesSnapshot = await getDocs(q);
        setCategories(categoriesSnapshot.docs.map(doc => { return doc.data() as Category }));
        setSections(sectionsSnapshot.docs.map(doc => { return doc.data() as Section }));
        setCases(casesSnapshot.docs.map(doc => { return doc.data() as CaseSummary }));
        setDone(true);
    }

    const playCase = async (_case: CaseSummary, pathology: boolean, morphology: boolean) => {
        if (subscriptions?.find(subscription => 
                Timestamp.now() <= subscription.next_billing_time && (
                    subscription.product_id === "fuve-premium" ||
                    (pathology && subscription.product_id === "fuve-standard") ||
                    (morphology && subscription.product_id === "fuve-morphology")
                )) 
                || premium 
                || (_case.lite && lite)) {
            console.log(i18n.language);
            const snapshot = await getDoc(doc(db, "app", "unity"));
            window.open(`${(process.env.NODE_ENV === "development" ? snapshot.data()?.development : snapshot.data()?.production) || "https://play.fuve.app/old/index.html"}?userid=${user?.uid}&caseid=${_case.id}&language=${i18n.language}`, "_self");
        }
        else setOverlay({
            display: "flex", content:
                <OverlayWindow>
                    <img src="/img/incorrect.png" style={{ "width": "50px", "height": "50px"}} />
                    {lite
                    ? (<h1 style={{"color": "white"}}>{t("Premium Required")}</h1>)
                    : (<h1>ERROR</h1>)
                    }
                    {lite
                    ? (<p className="limited-area" style={{"textAlign": "center", "color": "white"}}>{t("lite need premium")}</p>)
                    : (<p>{t("have-to-be-member")}</p>)
                    }
                    <a href="/payment/premium"><button className="button-subscribe" style={{ "maxWidth": "200px" }}>{t("buy-membershi")}</button></a>
                </OverlayWindow>
        });
    };

    useEffect(() => {
        downloadMainData();
    }, [])

    return <Navigate to={"/courses"} replace/>;
}

export default Cases;