import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { MultiText } from "../types/CustomTypes";
import "./styles/MultiTextInput.css"

type props = {
    value: MultiText,
    setForm: Dispatch<SetStateAction<any | undefined>>,
    field: string,
    useTextArea?: boolean,
    arrayField?: string,
    subfield?: string,
    subArrayField?: string,
    index?: number,
    subIndex?: number
    removeButton?: string | JSX.Element | React.ReactNode
}

function MultiTextInput(props: props) {
    const { value, setForm, field, useTextArea, index, subfield, subArrayField, subIndex, arrayField, removeButton } = props;
    const { t } = useTranslation();

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (index !== undefined && subIndex !== undefined && subfield && subArrayField) {
            setForm((form: any) => {
                let newArray = form[field][subfield];
                let newSubArray = newArray[index][subArrayField];
                newSubArray[subIndex] = {...newSubArray[subIndex], [e.target.name]: e.target.value}
                newArray[index] = {...newArray[index], [subArrayField]: newSubArray}
                return {
                    ...form!, 
                    [field]: {...form[field], [subfield]: newArray}}
            })
        }
        else if (index !== undefined && subfield && subArrayField) {
            setForm((form: any) => {
                let newArray = form[field][subfield];
                newArray[index] = {...newArray[index], [subArrayField]: {...newArray[index][subArrayField], [e.target.name]: e.target.value}}
                return {
                    ...form!, 
                    [field]: {...form[field], [subfield]: newArray}}
            })
        }
        else if (index !== undefined && subfield) {
            setForm((form: any) => {
                let newArray = form[field][subfield];
                newArray[index] = {...newArray[index], [e.target.name]: e.target.value}
                return {
                    ...form!, 
                    [field]: {...form[field], [subfield]: newArray}}
            })
        }
        else if (index !== undefined && arrayField) {
            setForm((form: any) => {
                let newArray = form[field];
                console.log(newArray[index]);
                newArray[index] = {...newArray[index], [arrayField]: {...newArray[index][arrayField], [e.target.name]: e.target.value}}
                console.log(newArray[index]);
                return {
                    ...form!, 
                    [field]: newArray}
            })
        }
        else setForm((form: any) => {
            return {
                ...form!, 
                [field]: {...form[field], [e.target.name]: e.target.value}}
        })
    }

    return (
        <div className="multi-input-area">
            <div style={{"display": "flex"}}>
                <div style={{"marginBottom": "10px", "flex": "1 2"}}>{t(subArrayField || subfield || arrayField || field)}</div>
                {removeButton}
            </div>
                <div className="multi-input">
                    <div className="multi-input-item">
                        <label>ES</label>
                        {useTextArea
                            ?<textarea name="es" className="flex-fill" value={value.es} onChange={handleChange}/>
                            :<input name="es" className="flex-fill" value={value.es} onChange={handleChange}/>
                        }
                    </div>
                    <div className="multi-input-item">
                        <label>EN</label>
                        {useTextArea
                            ?<textarea name="en" className="flex-fill" value={value.en} onChange={handleChange}/>
                            :<input name="en" className="flex-fill" value={value.en} onChange={handleChange}/>
                        }
                    </div>
                </div>
        </div>
    );
}

export default MultiTextInput;