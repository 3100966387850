import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import A from "../components/navigation/A";

function UsersMenu() {
    const { t } = useTranslation();
    return (
        <div className="limited-area">
            <div className="flex-fill centered-area">
                <div className="flex-fill flex-column gap15" style={{"justifyContent": "center", "alignItems": "center"}}>
                    <A href="/editor/user" className="button-purple" style={buttonStyle}>{t("Edit user")}</A>
                    <A href="/editor/users" className="button-purple" style={buttonStyle}>{t("Create users")}</A>
                </div>
            </div>
        </div>
    );
}

const buttonStyle: CSSProperties = {
    width: "100%",
    maxWidth: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

export default UsersMenu;