import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { useTranslation } from "react-i18next";
import { useOverlay } from "../context/OverlayProvider";
import './styles/Home.css';
import { doc, getDoc } from 'firebase/firestore';
import { Language, useLanguage } from "../context/LanguageProvider";
import { db } from '../backend/firebase';
import i18next from 'i18next';
import { useEffect, useState } from 'react';

function Home() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { t } = useTranslation();
    const [, setOverlay] = useOverlay();
    const [lang, changeLanguage] = useLanguage();
    const [language] = useLanguage();
    const [changeVideo, setChangeVideo] = useState("");

    async function handleDemo() {
        if (user) {
            const snapshot = await getDoc(doc(db, "app", "unity"));
            window.open(`${snapshot.data()?.production || "https://play.fuve.app/old/index.html"}?userid=${user.uid}&caseid=1uXiwrMYCpGurx2RZ03z&language=${i18next.language}`, "_self");
        }
        else navigate('/signup')
    }

    const playVideo = () => {
        setOverlay({ display: "flex", content: <video className="home-video" src="https://thumbnails.fuve.app/FUVE%20Trailer.mp4" title="FUVE Trailer" autoPlay loop controls></video> });
    };

    const languages = [
        { value: "en", },
        { value: "es", }
    ]

    useEffect(() => {

        if (language == 'es')
            setChangeVideo("https://thumbnails.fuve.app/FUVE%20Intro.mp4");
        else
            setChangeVideo("https://media.fuve.app/Videos/Home/FUVE_Trailer_Ingles.mp4");

    }, [language, changeVideo])

    return (
        <div className="home-area">
            <div className='home-first-area'>
                <div className="home-up">
                    <video className='home-video-first' src={changeVideo} autoPlay muted loop controls></video>
                    <div className="home-intro">
                        <div style={{ "display": "flex", "flex": "1 2", "flexDirection": "column"}}>
                            <p style={{ "fontSize": "40px", "display": "flex", "width": "auto", "fontWeight": "700" }}>{t("home-title")}</p>
                            <p style={{ "fontSize": "20px", "display": "flex", "flex": "1 2", "fontWeight": "300" }}>{t("home-explain")}</p>
                            <div>
                            <button onClick={handleDemo} className="button-subscribe">{t("Start-DEMO")}</button>
                            </div>
                            <div style={{ "display": "flex", "gap": "15px", "flexDirection": "row", "width": "100%"}}>
                                <a href='https://play.google.com/store/apps/details?id=com.neuronmexico.fuvelite' target="_blank">
                                    <img src="/playstore.png" style={{ "width": "100%" }}></img>
                                </a>
                                <a href='https://apps.apple.com/mx/app/fuve/id1538047424' target="_blank">
                                    <img src="/appstore.png" style={{ "width": "100%" }}></img>
                                </a>
                            </div>
                        </div>
                        <div className='image-qr-container'>
                            <img className="qr-logo" src="/FUVE_API_QR.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-second-area'>
                <div className='what-is-FUVE'>
                    <div style={{ "width": "auto" }}>
                        <p style={{ "width": "auto", "fontSize": "20px" }}>{t("WHAT-IS-FUVE")}</p>
                        <p style={{ "fontSize": "40px", "display": "flex", "width": "auto", "fontWeight": "700" }}>{t("fuve-explain-title")}</p>
                        <p style={{ "fontSize": "20px", "display": "flex", "flex": "1 2", "fontWeight": "300" }}>{t("fuve-explain")}</p>
                    </div>
                </div>
                <div className="slider-container">
                    <div className="slider-image">
                        <img style={{ "width": "100%" }} src="/PatologiaAbdominal.jpg"></img>
                        <img style={{ "width": "100%" }} src="/Gemelar.jpg"></img>
                        <img style={{ "width": "100%" }} src="/CardiologiaII.jpg"></img>
                        <>{ }</>
                    </div>
                </div>
            </div>
            <div className='home-second-area'>
                <div className='what-is-FUVE'>
                    <img style={{ "width": "100%", "maxWidth": "550px" }} src="/02.png"></img>
                    <div style={{ "display": "flex", "flexDirection": "column", "flex": "1 2" }}>
                        <p style={{ "fontSize": "25px", "fontWeight": "400", "display": "flex", "flex": "1 2" }}>{t("WHY-FUVE")}</p>
                        <p style={{ "fontSize": "40px", "display": "flex", "width": "auto", "fontWeight": "700" }}>{t("why-fuve-title")}</p>
                        <p style={{ "fontSize": "20px", "display": "flex", "flex": "1 2" }}>
                            {t("why-fuve-explain")}
                        </p>
                    </div>
                </div>
            </div>
            {/*<div className='home-third-area'>
                <div className='home-grid'>
                    <div className='home-grid-card'>
                        <img style={{ "height": "80px" }} src="/gear.png"></img>
                        <h3 style={{ "fontSize": "25px" }}>{t("exclusive-cases")}</h3>
                        <p style={{ "fontSize": "20px", "textAlign": "center" }}>
                            {t("exclusive-cases-explain")}
                        </p>
                    </div>
                    <div className='home-grid-card'>
                        <img style={{ "height": "80px" }} src="/clock.png"></img>
                        <h3 style={{ "fontSize": "25px", "textAlign": "center" }}>{t("save-money")}</h3>
                        <p style={{ "fontSize": "20px", "textAlign": "center" }}>
                            {t("keep-update")}
                        </p>
                    </div>
                    <div className='home-grid-card'>
                        <img style={{ "height": "80px" }} src="/ultrasound.png"></img>
                        <h3 style={{ "fontSize": "25px" }}>{t("practical")}</h3>
                        <p style={{ "fontSize": "20px", "textAlign": "center" }}>
                            {t("use-your-computer")}
                        </p>
                    </div>
                </div>
    </div>*/}
            <div className='home-second-area' style={{ "width": "calc(100% - 30px)" }}>
                <div className='what-is-FUVE'>
                    <div style={{ "display": "flex", "flexDirection": "column", "flex": "1 2"}}>
                        <h2 style={{ "fontSize": "25px", "fontWeight": "400", "display": "flex", "flex": "1 2" }}>{t("buy-membership-now")}</h2>
                        <h1 style={{ "fontSize": "48px", "display": "flex", "flex": "1 2" }}>{t("start-now")}</h1>
                        <a href='/pricing'><button className="button-subscribe">{t("buy-membershi")}</button></a>
                    </div>
                    <div className="slider-container">
                        <div className="slider-image">
                            <img style={{ "width": "100%" }} src="/PatologiaAbdominal.jpg"></img>
                            <img style={{ "width": "100%" }} src="/Gemelar.jpg"></img>
                            <img style={{ "width": "100%" }} src="/CardiologiaII.jpg"></img>
                            <>{ }</>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-four-area'>
                <div className='learn-fuve'>
                    <div style={{ "width": "100%", "maxWidth": "650px" }}>
                        <img style={{ "position": "relative", "width": "100%" }} src="/images-ultra.png"></img>
                    </div>
                    <div style={{ "display": "flex", "flexDirection": "column", "flex": "1 2"}}>
                        <p style={{ "fontSize": "40px", "display": "flex", "width": "auto", "fontWeight": "700" }}>{t("learn-with-FUVE")}</p>
                        <div style={{ "fontSize": "20px", "display": "flex", "flex": "1 2" }}>
                            <p style={{ "fontSize": "20px", "display": "flex", "flex": "1 2" }}>
                                {t("the-evolution")}
                            </p>
                        </div>
                        {/*<button className="button-subscribe">Contáctanos</button>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;