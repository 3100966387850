import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { FormEvent, useEffect, useState } from "react";
import { db } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import { Case } from "../components/types/CustomTypes";

function Transalator() {
    const [cases, setCases] = useState<Case[]>([]);
    const [selectedCase, setSelectedCase] = useState<Case>();
    const [done, setDone] = useState(false);

    const downloadMainData = async () => {
        const q = query(collection(db, "cases"), where("visibility", "==", true));
        const casesSnapshot = await getDocs(q);
        const downloadedCases = casesSnapshot.docs.map(doc => { return doc.data() as Case });
        setCases(downloadedCases);
        if (casesSnapshot.docs.length > 0) {
            setSelectedCase(downloadedCases[0]);
        }
        setDone(true);
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (selectedCase) 
                await updateDoc(doc(db, "cases", selectedCase?.id), {
                    title: selectedCase.title,
                    hiddenTitle: selectedCase.hiddenTitle,
                    descriptionPreview: selectedCase.descriptionPreview
                });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        downloadMainData();
    }, [])

    if (!done) return <Loading/>

    return (
        <div className="limited-area expandw">
            <form onSubmit={handleSubmit} className="centered-area expandw flex-column" style={{"gap": "20px"}}>
                <select onChange={(e) => setSelectedCase(cases[Number.parseInt(e.target.value) || 0])}>
                    {cases.map((_case, index) => (
                        <option key={_case.id} value={index}>{_case.title.es}</option>
                    ))}
                </select>
                <div className="flex expandw gap15">
                    <div className="flex-fill">Español</div>
                    {"-->"}
                    <div className="flex-fill">Ingles</div>
                </div>
                <div className="flex expandw gap15">
                    <input className="flex-fill" value={selectedCase?.title.es} onChange={(e) => setSelectedCase(_case => {
                        if (_case) return {
                            ..._case, 
                            title: {..._case.title, es: e.target.value}}
                    })}/>
                    {"-->"}
                    <input className="flex-fill" value={selectedCase?.title.en} onChange={(e) => setSelectedCase(_case => {
                        if (_case) return {
                            ..._case, 
                            title: {..._case.title, en: e.target.value}}
                    })}/>
                </div>
                <div className="flex expandw gap15">
                    <input className="flex-fill" value={selectedCase?.hiddenTitle.es} onChange={(e) => setSelectedCase(_case => {
                        if (_case) return {
                            ..._case, 
                            hiddenTitle: {..._case.hiddenTitle, es: e.target.value}}
                    })}/>
                    {"-->"}
                    <input className="flex-fill" value={selectedCase?.hiddenTitle.en} onChange={(e) => setSelectedCase(_case => {
                        if (_case) return {
                            ..._case, 
                            hiddenTitle: {..._case.hiddenTitle, en: e.target.value}}
                    })}/>
                </div>
                <div className="flex expandw gap15">
                    <input className="flex-fill" value={selectedCase?.descriptionPreview.es} onChange={(e) => setSelectedCase(_case => {
                        if (_case) return {
                            ..._case, 
                            descriptionPreview: {..._case.descriptionPreview, es: e.target.value}}
                    })}/>
                    {"-->"}
                    <input className="flex-fill" value={selectedCase?.descriptionPreview.en} onChange={(e) => setSelectedCase(_case => {
                        if (_case) return {
                            ..._case, 
                            descriptionPreview: {..._case.descriptionPreview, en: e.target.value}}
                    })}/>
                </div>
                <button className="">Submit</button>
            </form>
        </div>
    );
}

export default Transalator;