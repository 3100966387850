import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../backend/files";
import { db } from "../backend/firebase";
import Loading from "../components/animations/Loading";
import MultiTextInput from "../components/input/MultiTextInput";
import { Section } from "../components/types/CustomTypes";
import { useLanguage } from "../context/LanguageProvider";
import "./styles/Editor.css"

function SectionEditor() {
    const [sections, setSections] = useState<Section[]>([]);
    const [sectionForm, setSectionForm] = useState<Section>();
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
    const [done, setDone] = useState(false);
    const [fileData, setFileData] = useState<File>();
    const { t } = useTranslation();
    const [language] = useLanguage();

    const downloadMainData = async () => {
        const sectionQuery = query(collection(db, "sections"));
        const sectionsSnapshot = await getDocs(sectionQuery);
        const downloadedSections = sectionsSnapshot.docs.map(doc => { return doc.data() as Section });
        setSections(downloadedSections);
        setDone(true);
    }

    async function addSection() {
        try {
            let newSection: Section = {
                id: "",
                title: {
                    es: "Nueva seccion",
                    en: "New section"
                },
                visibility: false
            }
            const docRef = await addDoc(collection(db, "sections"), newSection);
            await updateDoc(doc(collection(db, "sections"), docRef.id), { id: docRef.id });
            setSelectedSectionIndex(0);
            setDone(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteSection() {
        try {
            if (window.confirm('¿Esta seguro que desea eliminar la seccion <' + sectionForm?.title[language] + '>?'))
            {
                const backupDoc = await getDoc(doc(db, "sections", sectionForm!.id));
                await setDoc(doc(db, "sections_recycle_bin", sectionForm!.id), backupDoc.data());
                await deleteDoc(doc(db, "sections", sectionForm!.id));
                setSelectedSectionIndex(0);
                setDone(false);
            }
        } catch (error) {
            console.log(error);
        }
        
    }

    const fileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFileData(e.target.files![0]);
    };

    useEffect(() => {
        if (!done) downloadMainData();
    }, [done])

    useEffect(() => {
        if (sections.length > 0) setSectionForm(sections[selectedSectionIndex]);
    }, [sections, selectedSectionIndex])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (fileData) {
                const thumbnailUri = await uploadImage(fileData, "Thumbnails/Sections", sectionForm!.id);
                setSectionForm(section => {return {...section!, thumbnail: thumbnailUri}});
                await updateDoc(doc(db, "sections", sectionForm!.id), {...sectionForm, thumbnail: thumbnailUri});
            }
            else await updateDoc(doc(db, "sections", sectionForm!.id), sectionForm);
            setSelectedSectionIndex(0);
            setDone(false);
        } catch (error) {
            console.log(error);
        }
    }

    if (!done) return <Loading/>

    return (
        <>
            <div className="limited-area">
                <div className="centered-area" style={{"flex": "1 2"}}>
                    <form onSubmit={handleSubmit} className="centered-area expandw flex-column" style={{"gap": "20px"}}>
                        {t("Case") + ":"}
                        <select className="flex-fill case-editor-select" onChange={(e) => setSelectedSectionIndex(e.target.selectedIndex)}>
                            {sections.map((section, index) => (
                                <option key={section.id} value={index}>{section.title.es}</option>
                            ))}
                        </select>
                        <div className="flex gap15">
                            <div onClick={addSection} className="button-purple limited-area" style={{ "maxWidth": "200px" }}>Add section</div>
                            {sectionForm && <div onClick={deleteSection} className="button-red limited-area" style={{ "maxWidth": "200px" }}>Delete section</div>}
                        </div>

                        {sectionForm && (
                            <>
                                <div>
                                    <label>{t("Visibility")}:</label>
                                    <input onChange={(e) => setSectionForm(form => { return {...form!, visibility: e.target.checked}})} checked={sectionForm?.visibility || false} type={"checkbox"}/>
                                </div>
                                <div className="case-editor-multi">
                                    <MultiTextInput value={sectionForm.title} field="title" setForm={setSectionForm}/>
                                </div>
                                <div className="case-editor-multi multi-input-area" style={{ "alignItems": "center", "gap": "10px" }}>
                                    {t("Thumbnail") + ":"}
                                    <input type={"file"} accept="image/jpeg,image/png" onChange={fileChangeHandler}/>
                                    <img src={sectionForm.thumbnail} style={{"maxHeight": "300px"}}/>
                                </div>
                            </>
                        )}
                        <button className="button-purple">Submit</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SectionEditor;