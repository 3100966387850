import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";

type props = {
    className?: string,
    href?: string,
    onClick?: () => void;
    children: string | JSX.Element | React.ReactNode,
    style?: CSSProperties
}

function A(props: props) {
    const navigate = useNavigate();
    return (
        <a href={props.href} onClick={() => {
            if (props.onClick) props.onClick();
        }} 
        className={"A " + props.className}
        style={props.style}>
            {props.children}
        </a>
    );
}

export default A;