import { FirebaseError } from "firebase/app";
import { sendPasswordResetEmail } from "firebase/auth";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { auth } from "../backend/firebase";
import { useOverlay } from "../context/OverlayProvider";


function PasswordRecovery() {
    const [user, setUser] = useState({ email: ""});
    const [error, setError] = useState("");
    const { t } = useTranslation();
    const [, setOverlay] = useOverlay();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, user.email);
            setOverlay({display: "flex", content: <>Revisa tu correo para restablecer tu contraseña</>});
        } catch (error) {
            if (error instanceof FirebaseError) {
                setError(error.message); //works
            } else {
                console.log(error); // this could still happen
            }
        }
    }

    const handleChange = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
        setUser({...user, [name]: value});
        setError("");
    }

    return (
        <div className="login-container">
            <form className="login" onSubmit={handleSubmit}>
                <h1 className="login-title">{t("password-recovery")}</h1>
                <div className="login-subtitle">
                    {t("write-your-email")}
                </div>
                <div className="flex expandw">
                    <input 
                        className="login-input flex-fill"
                        placeholder={t("email")}
                        type="email" 
                        name="email" 
                        id="email"
                        onChange={handleChange}
                        required/>
                </div>
                <div className="input-error">{error}</div>
                <button className="button-purple expandw login-button">{t("send-link")}</button>
            </form>
        </div>
    );
}

export default PasswordRecovery;