import { collection, doc, getDoc, getDocs, query, Timestamp, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { db } from "../backend/firebase";
import Collapsible from "../components/animations/Collapsible";
import Loading from "../components/animations/Loading";
import OverlayWindow from "../components/overlay/OverlayWindow";
import { CaseSummary, Category, Section } from "../components/types/CustomTypes";
import { useAuth } from "../context/AuthProvider";
import { useLanguage } from "../context/LanguageProvider";
import { useOverlay } from "../context/OverlayProvider";
import './styles/Cases.css'

function Course() {
    const { i18n } = useTranslation();
    const { user, admin, lite, premium, userData } = useAuth();
    const [, setOverlay] = useOverlay();
    const [categories, setCategories] = useState<Category[]>([]);
    const [section, setSection] = useState<Section>();
    const [cases, setCases] = useState<CaseSummary[]>([]);
    const [done, setDone] = useState(false);
    const { subscriptions } = useAuth();
    const { t } = useTranslation();
    const [language] = useLanguage();
    const { sectionid } = useParams();

    const downloadMainData = async () => {
        let q = query(collection(db, "cases"), where("visibility", "==", true));
        if (admin) q = query(collection(db, "cases"));
        const categoriesSnapshot = await getDocs(query(collection(db, "categories"), where("section", "==", sectionid)));
        const sectionsSnapshot = await getDoc(doc(db, "sections", sectionid!));
        const casesSnapshot = await getDocs(q);
        setCategories(categoriesSnapshot.docs.map(doc => { return doc.data() as Category }));
        setSection(sectionsSnapshot.data() as Section);
        setCases(casesSnapshot.docs.map(doc => { return doc.data() as CaseSummary }));
        setDone(true);
    }

    const playCase = async (_case: CaseSummary, pathology: boolean, morphology: boolean) => {
        if (subscriptions?.find(subscription => 
                Timestamp.now() <= subscription.next_billing_time && (
                    subscription.product_id === "fuve-premium" ||
                    (pathology && subscription.product_id === "fuve-standard") ||
                    (morphology && subscription.product_id === "fuve-morphology")
                )) 
                || premium 
                || (_case.lite && lite)) {
            console.log(i18n.language);
            const snapshot = await getDoc(doc(db, "app", "unity"));
            window.open(`${(process.env.NODE_ENV === "development" ? snapshot.data()?.development : snapshot.data()?.production) || "https://play.fuve.app/old/index.html"}?userid=${user?.uid}&caseid=${_case.id}&language=${i18n.language}`, "_self");
        }
        else setOverlay({
            display: "flex", content:
                <OverlayWindow>
                    <img src="/img/incorrect.png" style={{ "width": "50px", "height": "50px" }} />
                    {lite
                    ? (<h1>{t("Premium Required")}</h1>)
                    : (<h1>ERROR</h1>)
                    }
                    {lite
                    ? (<p className="limited-area" style={{"textAlign": "center"}}>{t("lite need premium")}</p>)
                    : (<p>{t("have-to-be-member")}</p>)
                    }
                    <a href="/payment/premium"><button className="button-subscribe" style={{ "maxWidth": "200px" }}>{t("buy-membershi")}</button></a>
                </OverlayWindow>
        });
    };

    useEffect(() => {
        downloadMainData();
    }, [])

    if (!done) return <Loading/>

    return (
        <div className="limited-area flex-column" style={{"gap": "40px"}}>
            <div className="expandw" style={{"display": "flex", "position": "relative", "overflow": "hidden", "maxWidth": "1500px", "borderRadius": "20px", "justifyContent": "center", "alignItems": "center"}}>
                <img src={section?.thumbnail} style={{"maxWidth": "300px", "aspectRatio": "auto", "filter": "blur(0.5rem)"}}/>
                <div style={{fontWeight: "700", "position": "absolute", "bottom": 0, "width": "100%", "display": "flex", justifyContent: "center", "backgroundColor": "#0000002D", "height": "100px", "alignItems": "center", "fontSize": "30px"}}>{section?.title[language]}</div>
            </div>
            <div className="centered-area flex-column expandw" style={{"gap": "30px"}}>
                        {categories.filter(category => cases.flatMap(_case => _case.category).includes(category.id)).sort(function (a, b) {
                            /*if (i18n.language === "en" && a.title.en && b.title.en) return a.title.en.localeCompare(b.title.en);
                            else*/
                            if (i18n.language === "es") return a.title.es.localeCompare(b.title.es);
                            else return a.title.en!.localeCompare(b.title.en!);
                        }).map(category => (
                            <Collapsible
                                key={category.id}
                                src={category.icon || "/img/correct-icon.png"}
                                imgStyle={{ "width": "25px" }}
                                title={i18n.language === "es" ? category.title.es : category.title.en}
                                style={{ "fontSize": "20px", "padding": "0 15px" }}>
                                <div className="case-list">
                                    {cases.filter(_case => _case.category === category.id && !_case.comingSoon).sort((a, b) => {
                                        if (i18n.language === "es") return a.title.es.localeCompare(b.title.es);
                                        else return a.title.en!.localeCompare(b.title.en!);
                                    }).map(_case => (
                                        <div key={_case.id} className="case-container" onClick={() => playCase(_case, sectionid === "cn8rc85TNHwWnpjFcUQz", sectionid === "6jPqbIFShqacV65aI312")}>
                                            <img
                                                className="case-thumbnail"
                                                src={process.env.PUBLIC_URL + "/img/default-case.png"}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = process.env.PUBLIC_URL + "/img/default-case.png";
                                                    /*currentTarget.className="";
                                                    currentTarget.setAttribute("style","width: auto");
                                                    currentTarget.setAttribute("style","height: 100%");*/
                                                }} />
                                            <div className="case-title">
                                                {userData?.progress?.includes(_case.id)
                                                ? <h2 style={{ "margin": 0 }}>{_case.hiddenTitle[language]}</h2>
                                                : <h2 style={{ "margin": 0 }}>{_case.title[language]}</h2>
                                                }
                                                <span>{i18n.language === "es" ? _case.descriptionPreview.es : _case.descriptionPreview.en}</span>
                                            </div>
                                            {!admin && <div className="case-label">{lite && _case.lite && (<div className="lite-label">LITE</div>)}</div>}
                                            {admin && <div className="case-label">{!_case.visibility && (<div className="lite-label">ADMIN</div>)}</div>}
                                        </div>
                                        /*<div className="">{_case.title.es}</div>*/
                                    ))}
                                </div>
                            </Collapsible>
                        ))}
            </div>
        </div>

    );
}

export default Course;