import axios from "axios";
import { createUserWithEmailAndPassword, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithRedirect, signOut, User, UserCredential } from "firebase/auth";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { auth, db } from "../backend/firebase";
import { Subscription } from "../components/types/CustomTypes";

type props = {
    children: string | JSX.Element | React.ReactNode
}

export type UserData = {
    medicalCenter?: string,
    medicalLocation?: string,
    specialty?: string,
    subspecialty?: string,
    progress?: string[]
}

export type FUVE_User = {
    email: string,
    id: string,
    subscriptions?: Subscription[],
    lite: boolean,
    premium: boolean,
    admin?: boolean,
    medicalCenter?: string,
    medicalLocation?: string,
    specialty?: string,
    subspecialty?: string,
    progress?: string[]
}

const apiUri = process.env.REACT_APP_API;

type contextType = {
    login: (email: string, password: string) => Promise<UserCredential>,
    signup: (email: string, password: string) => Promise<UserCredential>,
    loginWithGoogle: () => Promise<void>,
    loginWithFacebook: () => Promise<void>,
    loginWithApple: () => Promise<void>,
    logout: () => Promise<void>,
    user: User | null,
    loading: boolean,
    lite: boolean,
    premium: boolean,
    admin?: boolean,
    subscriptions?: Subscription[],
    setSubscriptions: Dispatch<SetStateAction<Subscription[]>>,
    userData: UserData | undefined,
    setUserData: Dispatch<SetStateAction<UserData | undefined>>,
    createToken: () => Promise<void>
}

const authContext = createContext<contextType>({
    login: async () => await signInWithEmailAndPassword(auth,"",""),
    signup: async () => await signInWithEmailAndPassword(auth,"",""),
    loginWithGoogle: async () => {},
    loginWithFacebook: async () => {},
    loginWithApple: async () => {},
    logout: async () => {},
    user: null,
    loading: true,
    lite: false,
    premium: false,
    admin: false,
    subscriptions: [],
    setSubscriptions: () => {},
    userData: undefined,
    setUserData: () => {},
    createToken: async () => {},
});

export const useAuth = () => {
    return useContext(authContext);
}

function AuthProvider(props: props) {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [lite, setLite] = useState<boolean>(true);
    const [premium, setPremium] = useState<boolean>(true);
    const [admin, setAdmin] = useState<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [userData, setUserData] = useState<UserData>();

    const signup = async (email: string, password: string) => 
        await createUserWithEmailAndPassword(auth, email, password);

    const loginWithGoogle = async () => {
        const google = new GoogleAuthProvider();
        await signInWithRedirect(auth, google);
    }

    const loginWithFacebook = async () => {
        const facebook = new FacebookAuthProvider();
        await signInWithRedirect(auth, facebook);
    }

    const loginWithApple = async () => {
        const apple = new OAuthProvider("apple.com");
        await signInWithRedirect(auth, apple);
    }

    const login = async (email: string, password: string) => 
        await signInWithEmailAndPassword(auth, email, password);

    const logout = async () => 
        await signOut(auth);

    const createToken = async () => {
        const response = await axios.get<string>(`${apiUri}/token`, {headers: {uid: user!.uid}})
        console.log(response.data);
    }

    const downloadUserData = async (currentUser: User) => {
        const userData = await getDoc(doc(db, "users", currentUser.uid));
        if (!userData.exists()) {
            await setDoc(doc(db, "users", currentUser.uid), {
                created: Timestamp.now(),
                id: currentUser.uid,
                email: currentUser.email,
                name: currentUser.displayName,
                favoriteCases: [],
                medicalCenter: "",
                medicalLocation: "",
                photo: "",
                premium: false,
                specialty: "",
                subspecialty: "",
                lite: false,
            });
        }
        setSubscriptions(userData.data()?.subscriptions);
        setUserData(userData.data() as UserData);
        setAdmin(userData.data()?.admin || false);
        setLite(userData.data()?.lite || false);
        setPremium(userData.data()?.premium || false);
        setLoading(false);
    }

    useEffect(() => {
        onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
            if (currentUser) downloadUserData(currentUser);
            else {
                setSubscriptions([]);
                setAdmin(false);
                setLite(false);
                setPremium(false);
                setLoading(false);
            }
        });
    }, []);

    return (
        <authContext.Provider value={{
            login, 
            signup, 
            loginWithGoogle, 
            loginWithFacebook, 
            loginWithApple, 
            logout, 
            user, 
            loading, 
            lite, 
            premium,
            admin, 
            subscriptions, 
            setSubscriptions,
            userData,
            setUserData,
            createToken
        }}>
            {props.children}
        </authContext.Provider>
    );
}

export default AuthProvider;