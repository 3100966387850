import { createContext, useContext, useState } from "react";

type props = {
    children: string | JSX.Element | React.ReactNode
}

export enum Language {
    en = "en",
    es = "es"
}

type LanguageContextType = [Language, (language: Language) => void]

const languageContext = createContext<LanguageContextType>([Language.en, () => null]);

export const useLanguage = () => {
    return useContext(languageContext);
}

function LanguageProvider(props: props) {
    const [language, setLanguage] = useState<Language>(window.localStorage.getItem("language") ? (window.localStorage.getItem("language") === "es" ? Language.es : Language.en ) : Language.en);

    const changeLanguage = (language: Language) => {
        setLanguage(language);
        window.localStorage.setItem("language", language);
    }

    return (
        <languageContext.Provider value={[language, changeLanguage]}>
            {props.children}
        </languageContext.Provider>
    );
}

export default LanguageProvider;