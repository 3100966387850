import { useOverlay } from "../../context/OverlayProvider";

type props = {
    children: string | JSX.Element | React.ReactNode
}

function OverlayWindow(props: props) {
    const [{ display }, setOverlay] = useOverlay();
    return (
        <div style={{"backgroundColor": "#dddddd", "width": "100%", "height": "100%", "maxHeight": "400px", "maxWidth": "500px", "display": "flex", "borderRadius": "20px", "justifyContent": "center", "alignItems": "center", "flexDirection": "column", "position": "relative"}}>
            <img onClick={() => setOverlay(overlay => {return {...overlay, display: "none"}})} src="/img/x-icon.png" style={{"cursor": "pointer","position": "absolute", "top": "10px", "right": "10px", "width": "20px", "height": "20px"}}/>
            {props.children}
        </div>
    );
}

export default OverlayWindow;