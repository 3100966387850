import { CSSProperties } from 'react';
import './styles/Collapsible.css'

type props = {
    style?: CSSProperties,
    imgStyle?: CSSProperties,
    children: string | JSX.Element | React.ReactNode,
    src?: string,
    title?: string
}

function Collapsible(props: props) {
    return (
        <div style={props.style}>
            <div >
                <img src={props.src} style={props.imgStyle} />
                {props.title}
            </div>
            {props.children}
        </div>    
    );
}

const sliderStyle: CSSProperties = {
    display: "flex",
    flexWrap: "nowrap"
}

export default Collapsible;