import "./styles/Requirements.css"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

function Requirements() {

    const { t } = useTranslation();
    const osValues = navigator.platform
    const [isWindows, setIsWindows] = useState(false);
    
    const faqs: { title: string, description: string }[] = [
        { "title": t("Cost to be PREMIUM"), "description": t("To be premium it is a monthly payment of 250 dollars or you can contact us to receive a special price in case it is a special description designed for education")},
        { "title": t("Payment methods"), "description": t("We accept credit or debit cards, you can also use paypal to make the purchase") },
        { "title": t("Ultrasound is cut off"), "description": t("We use a special 'shader' to be able to show the different ultrasounds, many times this can cause errors on different Macs, but not on Windows computers")},
        { "title": t("My computer can't run properly FUVE"), "description": t("Check that your computer has the minimum requirements to be able to run FUVE")},
        { "title": t("Where can I contact you?"), "description": t("soporte@medicinafetalmexico.com")},
        { "title": t("Can I return my purchase?"), "description": t("We do NOT accept returns upon purchase")}
    ];

    useEffect(() => {
        if (osValues == 'Win32' || osValues == 'Win64') {
            setIsWindows(true);
        } else {
            setIsWindows(false);
        }
        console.log(faqs[0].title);
    }, [osValues])

    return (
        <div className="requirements-container">
            <h1>{t("Frequent questions")}</h1>
            <br></br>
            <details className="collapsible" style={{ "maxWidth": "1000px", "backgroundColor": "white" }} >
                <summary className="collapsible-background">
                    <div className="faqs-title">{t("What're the minimum requirements?")}</div>
                </summary>
                <div className="requirements-container">
                    <div className="requirements-card">
                        <h2>{t("Requirements to use FUVE")}</h2>
                        <div className="card-area">
                            <div>
                                <h4>{t("Minimum specifications required")}</h4>
                                <ul>
                                    <li>{t("64-bit operating system")}</li>
                                    <li>4GB RAM</li>
                                    <li>{t("Integrated Graphics with WebGL 2.0 / OpenGL ES 3.0 Support")}</li>
                                    <li>Dual-core Intel/AMD CPU</li>
                                </ul>
                            </div>
                            <img style={{ "width": "200px" }} src="/pc_icon.png" />
                        </div>
                    </div>
                    <br></br>
                    <div className="requirements-card">
                        <h4>{t("Supported Browser")}</h4>
                        <br></br>
                        <div>
                            <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center", "flexWrap": "wrap" }}>
                                <div style={{ "display": "flex", "flexDirection": "row", "gap": "15px", "flexWrap": "wrap", "justifyContent": "center", "alignItems": "center" }}>
                                    <a href="https://www.mozilla.org/es-MX/firefox/new/" target="_blank"><img style={{ "width": "100px" }} src="/firefox.png" /></a>
                                    <a href="https://www.google.com/chrome/?brand=BNSD&gclid=CjwKCAjw4c-ZBhAEEiwAZ105RTBkB648G1EhLYFtHIFCnw1lK40etCZWJhUxIG7CRGXz8Z0e9gZwzRoCKEwQAvD_BwE&gclsrc=aw.ds" target="_blank"><img style={{ "width": "100px" }} src="/chrome.png" /></a>
                                    <a href="https://www.opera.com/es/gx?edition=std-1&gclsrc=aw.ds&utm_campaign=OGX_MX_Search_EN_ES_Generic&utm_source=sa360_GOOGLE&utm_medium=pa&gclid=CjwKCAjw4c-ZBhAEEiwAZ105RUrDRQEBjATSj7GAoOEqdOXGwiiKt-ZNsKJfw4rsEF6AgCg39y2uSBoCYroQAvD_BwE&utm_id=CjwKCAjw4c-ZBhAEEiwAZ105RUrDRQEBjATSj7GAoOEqdOXGwiiKt-ZNsKJfw4rsEF6AgCg39y2uSBoCYroQAvD_BwE" target="_blank"><img style={{ "width": "100px" }} src="/Opera.png" /></a>
                                </div>
                            </div>
                            <br></br>
                            {!isWindows ? (
                                <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "alignItems": "center" }}>
                                    <p>{t("Recommended")}</p>
                                    <div style={{ "display": "flex", "flexDirection": "row", "gap": "15px" }}>
                                        <a href="https://www.mozilla.org/es-MX/firefox/new/" target="_blank"><img style={{ "width": "100px" }} src="/firefox.png" /></a>
                                    </div>
                                </div>
                            ) :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </details>
            {faqs.map(faqs => (
                <>
                <br></br>
                <details className="collapsible" style={{ "maxWidth": "1000px", "backgroundColor": "white" }} >
                <summary className="collapsible-background">
                    <div className="faqs-title">{faqs.title}</div>
                </summary>
                <div className="faqs-answer">{faqs.description}</div>
            </details>
                </>                
            ))}
        </div>
    );
}

export default Requirements;