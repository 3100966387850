import { doc, getDoc } from "firebase/firestore";
import i18next from "i18next";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { db } from "../../backend/firebase";
import { useAuth } from "../../context/AuthProvider";
import { Language, useLanguage } from "../../context/LanguageProvider";
import RSOption from "../custom/RSOption";
import A from "../navigation/A";

function Header() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, admin, loading, logout } = useAuth();
    const [lang, changeLanguage] = useLanguage();

    async function handleLogOut() {
        await logout();
        navigate('/login');
    }

    async function handleDemo() {
        if (user){
          const snapshot = await getDoc(doc(db, "app", "unity"));
          window.open(`${(process.env.NODE_ENV === "development" ? snapshot.data()?.development : snapshot.data()?.production) || "https://play.fuve.app/old/index.html"}?userid=${user.uid}&caseid=1uXiwrMYCpGurx2RZ03z&language=${i18next.language}`, "_self");
        } 
        else navigate('/signup')
    }

    function handleLanguage(e: {value: string; label: JSX.Element;} | null) {
        if (e) {
          let newLanguage: Language = Language.en;
          if (e.value === "es") newLanguage = Language.es;
          changeLanguage(newLanguage);
        }
    }

    return (
        <div className="upper-layout">
            <div className="upper-bar">
                <A href="/"><img src={process.env.PUBLIC_URL + "/img/logo.png"} className="logo" alt="logo"/></A>
                <div className="nav-bar">
                    <A href="/">{t("home")}</A>
                    <A href="/courses">{t("Courses")}</A>
                    <A onClick={handleDemo}>{t("demo")}</A>
                    {/*<A href="/">{t("contact us")}</A>*/
                    <A href="/pricing">{t("pricing")}</A>}
                    <A href="/requirements">{t("FAQs")}</A>
                    {admin && <A href="/admin">{t("Admin")}</A>}
                </div>
                {/*<div>Español</div>*/}
                <div className="box">
                        <Select 
                            styles={customStyles}
                            defaultValue={languages.find(language => language.value === lang) || languages[0]}
                            options={languages}
                            onChange={handleLanguage}/>
                    </div>
                    {user && <A href="/profile" style={{"display": "flex", "borderRadius": "50%", "overflow": "hidden"}}><img style={{"width": "30px", "cursor": "pointer"}} src={user?.photoURL || process.env.PUBLIC_URL + "/img/accountIcon.png"} alt="Account Icon"></img></A>}
                {!loading && (user
                    ? <A onClick={handleLogOut} className="login-navbar">{t("logout")}</A>
                    : <A href="/login" className="login-navbar">{t("login")}</A>
                )}
            </div>
        </div>
    );
}

const languages = [
    {value: "en", label: <RSOption title="English" src={process.env.PUBLIC_URL + "/img/us.svg"}/>},
    {value: "es", label: <RSOption title="Español" src={process.env.PUBLIC_URL + "/img/mx.svg"}/>}
]

const customStyles = {
    menu: (provided: any, state: any) => ({
        ...provided,
        width: state.selectProps.width,
        backgroundColor: "#392d486b",
      }),

    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'white',
      backgroundColor: state.isSelected ? '' : '',
      "&:hover": {
        backgroundColor: "#392d48"
      },
      display: "flex",
      alignItems: "center",
      gap: 5
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 150,
      display: 'flex',
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      
      return { 
        ...provided, 
        opacity, 
        transition, 
        color: "white",
        display: 'flex',
        alignItems: 'center',
        gap: 5
     };
    }
  }

export default Header;